import { saveSubmission } from "./submissionService.js";
import { saveUserFavorites } from "./unidyService.js";
import { state } from "../../store/state.js";
import { STATE } from "../../Experience/config.js";
import { submissionCompleted } from "../../store/submissionCompleted.js";
import { removeStorageItem, STORAGE_KEYS } from "./storageService.js";

export const completeSubmission = async () => {
  await saveSubmission();
  await saveUserFavorites();

  state.set(STATE.SHARE);
  submissionCompleted.set(true);
  removeStorageItem(STORAGE_KEYS.HALL_OF_FAME);
};
