varying vec2 vUv;
uniform sampler2D texture1;
uniform sampler2D texture2;
uniform float opacity; // New uniform

void main() {
    vec4 color1 = texture2D(texture1, vUv);
    vec4 color2 = vec4(0.0);
    vec4 resultcolor = vec4(0.0);

    // Adjust for aspect ratio
    float image2Width = 0.42;
    float image2Height = image2Width / (9.0/16.0);

    // Calculate the bounds for the smaller image, keeping its aspect ratio
    vec2 lowerBounds = vec2(0.5 - image2Width / 2.0, 0.5 - image2Height / 2.0);
    vec2 upperBounds = vec2(0.5 + image2Width / 2.0, 0.5 + image2Height / 2.0);

    if (vUv.x >= lowerBounds.x && vUv.x <= upperBounds.x && vUv.y >= lowerBounds.y && vUv.y <= upperBounds.y) {
        // Map the UVs to the smaller image's texture space
        vec2 centeredUV = (vUv - lowerBounds) / (upperBounds - lowerBounds);
        color2 = texture2D(texture2, centeredUV);
    }

    resultcolor = color2.a > 0.0 ? pow(color2, vec4(0.5)) : color1;
//    resultcolor = color2.a > 0.0 ? color2 : color1;
    resultcolor.a *= opacity; // Apply opacity
    gl_FragColor = resultcolor;
}
