import Experience from "../Experience.js";
import Environment from "./Environment.js";
import HallOfFame from "./HallOfFame.js";
import Video from "./Video.js";
import Frame from "./Frame.js";
import Wall from "./Wall.js";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // Wait for resources
    this.resources.on("ready", () => {
      window.Frame = new Frame();
      window.Video = new Video();

      // Setup
      this.hallOffFame = new HallOfFame();
      this.frame = window.Frame;
      this.video = window.Video;
      this.environment = new Environment();
      this.wall = new Wall();

      setTimeout(() => {
        this.frame.setFrameModels();
      });
    });
  }

  update() {}
}
