import Experience from "../Experience.js";
import gsap from "gsap";
import { state } from "../../store/state.js";
import {
  INTRO_ANIMATION_END_POSITION_Y,
  LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP,
  LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_MOBILE,
  LOOK_AROUND_CONTROLS_FACTOR_DETAIL_DESKTOP,
  LOOK_AROUND_CONTROLS_FACTOR_DETAIL_MOBILE,
  STATE,
} from "../config.js";

export default class Wall {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.raycaster = this.experience.raycaster;
    this.camera = this.experience.camera;
    this.controls = this.experience.controls;
  }

  clickVideoWallHandler() {
    this.activeVideo = this.raycaster.raycaster.currentHoverVideoWall?.object;

    if (!this.activeVideo) {
      return;
    }

    state.set(STATE.VIDEO_DETAIL_ENTER_ANIMATION_START);

    // Rotate the camera to the center of the VideoWall
    gsap.to(this.camera.cameraContainer.rotation, {
      duration: 0.4,
      delay: 0,
      ease: "power1.inOut",
      y: this.camera.calculateRotation(this.activeVideo.position.clone()),
      onStart: () => {
        this.controls.panControls.clearInertia();
      },
      onUpdate: () => {
        this.controls.lookAroundControls.animateCamera();
      },
      onComplete: () => {
        this.controls.panControls.updateRotationAfterAnimation(
          this.camera.calculateRotation(this.activeVideo.position.clone()),
        );
        this.controls.scrollControls.updateRotationScroll();
        state.set(STATE.VIDEO_DETAIL);
      },
    });

    // Move the camera up to the VideoWall height
    gsap.to(this.camera.instance.position, {
      y: 40,
      duration: 0.4,
      delay: 0,
      ease: "power1.inOut",
    });

    // Change camere FOV
    gsap.to(this.camera.instance, {
      fov: this.camera.calculateVideoWallFOV(),
      duration: 0.4,
      delay: 0,
      ease: "power1.inOut",
      onUpdate: () => {
        this.camera.instance.updateProjectionMatrix();
      },
    });

    // Set lookAroundControls factor
    gsap.to(this.controls.lookAroundControls, {
      duration: 1.4,
      delay: 0,
      ease: "power1.inOut",
      cameraFactorDesktop: LOOK_AROUND_CONTROLS_FACTOR_DETAIL_DESKTOP,
      cameraFactorMobile: LOOK_AROUND_CONTROLS_FACTOR_DETAIL_MOBILE,
    });
  }

  exitVideoWallHandler() {
    state.set(STATE.VIDEO_DETAIL_LEAVE_ANIMATION_START);

    // Change camera position back to the default
    gsap.to(this.camera.instance.position, {
      y: INTRO_ANIMATION_END_POSITION_Y,
      duration: 0.4,
      delay: 0,
      ease: "power1.inOut",
    });

    // Change camera FOV back to the default
    gsap.to(this.camera.instance, {
      fov: this.camera.calculateFOV(),
      duration: 0.4,
      delay: 0,
      ease: "power1.inOut",
      onUpdate: () => {
        this.camera.instance.updateProjectionMatrix();
      },
      onComplete: () => {
        state.set(STATE.FIRST_PERSON_ACTIVE);
      },
    });

    // Set lookAroundControls factor
    gsap.to(this.controls.lookAroundControls, {
      duration: 0.4,
      delay: 0,
      ease: "power1.inOut",
      cameraFactorDesktop: LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP,
      cameraFactorMobile: LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_MOBILE,
    });
  }
}
