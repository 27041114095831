<script>
    import {fade} from 'svelte/transition';
</script>

<div class="focus-overlay" transition:fade={{ duration: 600, delay: 300 }}></div>

<style>
	.focus-overlay {
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
      background: linear-gradient(270deg, rgb(0 0 0 / 0.8) 0%, rgb(0 0 0 / 0) 20%, rgb(0 0 0 / 0) 80%, rgb(0 0 0 / 0.8) 100%);
      z-index: -1;
	}
</style>


