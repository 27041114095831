<script>
  import {fade} from 'svelte/transition';
  import html2canvas from 'html2canvas';
  import {translate} from '../../translations/index.js';
  import {getPlayersByShortlist} from '../services/shortlistService.js';
  import {state} from '../../store/state.js';
  import {STATE} from '../../Experience/config.js';

  const closeModal = () => {
    state.set(STATE.FIRST_PERSON_ACTIVE);
  };

  const downloadVisual = (type, size) => {
    const visualElement = document.querySelector('.visual');
    let height = 1080;
    let width = 1080;

    if (size === 'story') {
      height = 1920;
      width = 1080;
    }

    html2canvas(visualElement, {
      useCORS: true,
      backgroundColor: null,
      height,
      scale: 1,
      width,
      onclone: (document) => {
        // eslint-disable-next-line no-param-reassign
        document.body.dataset.html2canvastype = size;
      },
    }).then(canvas => {
      canvas.toBlob(blob => {
        const fileName = 'shared-image.png';
        const file = new File([blob], fileName, {type: 'image/png'});

        // if navigator.share is avalable, use it. Else, download the image
        if (type === 'share') {
          navigator.share({
            files: [file],
            title: 'Pro League Hall of Fame',
            text: translate('hof.share.visual.share.text')
          }).then(() => {
            console.log('Image shared successfully');
          }).catch(error => {
            console.error('Error sharing the image:', error);
          });
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    });
  }
</script>

<div class="overlay" transition:fade={{ duration: 300 }}>
	<div class="success__inner">
		<div class="success__content">
			<h3 class="success__title">{translate('hof.share.title')}</h3>

			<p class="success__text">
				{translate('hof.share.text')}
			</p>
		</div>

		<div class="success__visual">
			<div class="success__visual__background"></div>

			<div class="visual">
				<div class="visual__corners"></div>

				<span class="visual__title">My Hall of Fame <span>picks</span></span>

				<div class="visual__images">
					{#each getPlayersByShortlist() as player}
						<div class="visual__item">
							<div class="visual__image-wrapper">
								<img class="visual__image" src="{player.imageUrl}" alt="{player.firstname} {player.lastname}">
								<div class="visual__image-overlay"></div>
							</div>

							<span class="visual__firstname">{player.firstname}</span>
							<span class="visual__lastname">{player.lastname}</span>
						</div>
					{/each}
				</div>

				<img class="visual__cta" src="/images/share/share-logo.png" alt="Logo Hall of Fame">
			</div>

			<span class="success__visual__share-title">{translate("hof.share.visual.title")}</span>

			<div class="success__visual__buttons">
				{#if navigator.share}
					<button class="btn btn--primary" on:click={() => downloadVisual('share', 'timeline')}>
						<span class="btn__label">
							<span>{translate('hof.share.visual.share.timeline')}</span>
							<span>{translate('hof.share.visual.share.timeline')}</span>
						</span>
					</button>
					<button class="btn btn--primary" on:click={() => downloadVisual('share', 'story')}>
						<span class="btn__label">
							<span>{translate('hof.share.visual.share.story')}</span>
							<span>{translate('hof.share.visual.share.story')}</span>
						</span>
					</button>
				{/if}

				<button class="btn btn--primary" on:click={() => downloadVisual('download', 'timeline')}>
					<span class="btn__label">
						<span>{translate('hof.share.visual.download.timeline')}</span>
						<span>{translate('hof.share.visual.download.timeline')}</span>
					</span>
				</button>
				<button class="btn btn--primary" on:click={() => downloadVisual('download', 'story')}>
					<span class="btn__label">
						<span>{translate('hof.share.visual.download.story')}</span>
						<span>{translate('hof.share.visual.download.story')}</span>
					</span>
				</button>
			</div>
		</div>

		<div class="success__buttons">
			<button class="btn btn--tertiary-inverted" on:click={closeModal}>
				<span class="btn__label">
					<span>{translate('hof.share.btn.close')}</span>
					<span>{translate('hof.share.btn.close')}</span>
				</span>
			</button>
		</div>
	</div>
</div>

<style>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: var(--color-black-60);
    display: flex;
    pointer-events: all;
	overflow: auto;
  }

  .success__inner {
    margin: auto;
    padding: 80px 16px;
    box-sizing: border-box;
    background: var(--color-white-90);
  }

  .success__visual {
    position: relative;
	padding: 16px;
	overflow: hidden;
  }

  .success__visual__background {
    position: absolute;
    top: 0;
	left: 0;
	height: 100%;
    width: 100%;
	transform: scale(1.3);
    background-image: url(/images/share/share-background.jpg);
	background-size: cover;
	background-position: bottom center;
	filter: blur(40px);
	opacity: 0.95;
  }

  .success__visual__share-title {
    position: relative;
    font-size: 16px;
	color: var(--color-white);
    font-weight: 700;
    line-height: 1.1;
  }

  .success__visual__buttons {
	display: flex;
	flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;
  }

  .success__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 640px;
    margin-bottom: 24px;
  }

  .success__title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.1;
  }

  .success__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }

  .success__buttons {
    display: flex;
    gap: 16px;
    margin-top: 40px;
  }

  .visual {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: clamp(1rem, 0.2889rem + 3.5556vw, 2rem);
    width: 100%;
    max-width: 640px;
    margin-bottom: 40px;
    aspect-ratio: 1/1;
    box-sizing: border-box;
    background-color: var(--color-grey-darker);
    background-image: url(/images/share/share-background.jpg);
    background-size: cover;
    background-position: bottom 50% center;
    color: var(--color-white);
  }

  .visual__corners {
    position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
    background-image: url(/images/share/corners-timeline.png);
  }

  .visual__image-wrapper {
    position: relative;
    margin-bottom: clamp(0.5rem, 0.1444rem + 1.7778vw, 1rem);
  }

  .visual__image-overlay {
    position: absolute;
    bottom:0;
    left: 0;
    z-index: 1;
    height: 70%;
    width: 100%;
    background: linear-gradient(180deg, rgb(0 0 0 / 0) 0%, var(--color-black-40) 100%);
  }

  .visual__image {
    width: 100%;
    height: 100%;
  }

  .visual__title {
    padding-top: 5%;
    font-weight: 500;
    color: var(--color-white);
    font-size: clamp(1.125rem, -0.2083rem + 6.6667vw, 3rem);
  }

  .visual__title span {
    color: var(--color-gold);
  }

  .visual__images {
    display: flex;
    flex-direction: row;
    gap: 2%;
    padding: 0 8%;
  }

  .visual__item {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    min-width: 0;
    width: 100%;
    color: var(--color-white);
    font-size: clamp(0.5625rem, -0.1042rem + 3.3333vw, 1.5rem);
    font-weight: 500;
  }

  .visual__lastname {
    color: var(--color-gold);
  }

  .visual__cta {
    width: 40%;
  }

  :global([data-html2canvastype='story']) .visual {
    width: 1080px;
    height: 1920px;
    max-width: none;
    gap: 240px;
  }

  :global([data-html2canvastype='story']) .visual__title {
    font-size: 88px;
  }

  :global([data-html2canvastype='story']) .visual__images {
    padding: 0 5%;
  }

  :global([data-html2canvastype='story']) .visual__item {
    font-size: 42px;
  }

  :global([data-html2canvastype='story']) .visual__cta {
    width: 70%;
  }

  :global([data-html2canvastype='timeline']) .visual {
    width: 1080px;
    height: 1080px;
    max-width: none;
  }

  :global([data-html2canvastype='timeline']) .visual__corners {
		background-image: url(/images/share/corners-timeline.png);
  }

  :global([data-html2canvastype='story']) .visual__corners {
		background-image: url(/images/share/corners-story.png);
  }

  :global([data-html2canvastype='timeline']) .visual__title {
    font-size: 80px;
  }

  :global([data-html2canvastype='timeline']) .visual__item {
    font-size: 42px;
  }

  @media screen and (min-width: 640px) {
    .success__inner {
      padding: 80px 24px;
    }

    .success__content {
      margin-bottom: 40px;
    }

    .success__title {
	  margin-bottom: 24px;
	  font-size: 32px;
	}

    .success__visual {
      padding: 40px;
    }

    .success__visual__share-title {
      font-size: 18px;
    }
  }
</style>
