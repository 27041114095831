import * as THREE from "three";
import Experience from "../Experience.js";
import { get } from "svelte/store";
import gsap from "gsap";
import { players } from "../../store/players.js";
import { activePlayer } from "../../store/activePlayer.js";

import frameVideoVertexShader from "../Shaders/frame-video/vertex.glsl";
import frameVideoFragmentShader from "../Shaders/frame-video/fragment.glsl";
import inactivePlayerVertexShader from "../Shaders/inactive-player/vertex.glsl";
import inactivePlayerFragmentShader from "../Shaders/inactive-player/fragment.glsl";
import frameThumbVertexShader from "../Shaders/frame-thumb/vertex.glsl";
import frameThumbFragmentShader from "../Shaders/frame-thumb/fragment.glsl";
import { state } from "../../store/state.js";
import {
  FADE_DURATION,
  LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP,
  LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_MOBILE,
  LOOK_AROUND_CONTROLS_FACTOR_DETAIL_DESKTOP,
  LOOK_AROUND_CONTROLS_FACTOR_DETAIL_MOBILE,
  STATE,
} from "../config.js";
import { shortlistedPlayers } from "../../store/shortlistedPlayers.js";

export default class Frame {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.raycaster = this.experience.raycaster;
    this.camera = this.experience.camera;
    this.controls = this.experience.controls;
    this.videoService = this.experience.videoService;

    this.frameModel = this.resources.items.frameModel.scene;
    this.scene.scene = this.scene.children.filter(
      (obj) => obj.name === "Scene",
    )[0];
    this.players = get(players);

    this.activePlayer = null;
    this.videoElement = null;
    this.frameContentIsAnimating = false;
  }

  calculatePosition(i, radius) {
    const numPoints = this.players.length;
    const offset = 3.911 / 2 - 0.045; // 3.911 = width of frame in blender
    const angleIncrement = (2 * Math.PI) / numPoints;

    const angle = i * angleIncrement;
    const screenrotation = (angle + offset / radius) * -1 - Math.PI / 2;
    const x = radius * Math.cos(angle + offset / radius);
    const y = radius * Math.sin(angle + offset / radius);

    return { screenrotation, x, y };
  }

  setFrameModels() {
    // Calculate and set the coordinates and rotation of each frame and add it to the scene
    for (let i = 0; i < this.players.length; i++) {
      const positionDataThumb = this.calculatePosition(i, 69.594);
      const positionDataInfo = this.calculatePosition(i, 68.8);

      // Add all frames to the scene
      const newModel = this.frameModel.clone();
      newModel.name = `frame_${i}`;
      newModel.playerId = i;
      newModel.rotation.set(0, positionDataThumb.screenrotation, 0);
      newModel.position.set(
        positionDataThumb.x.toFixed(2),
        9,
        positionDataThumb.y.toFixed(2),
      );
      this.scene.add(newModel);

      // Hide the clickable back of the frame
      const frameBack = newModel.children.find((obj) =>
        obj.name.includes("frame_back"),
      );
      frameBack.material.visible = false;

      // Set the image and the info for each frame
      this.setFrameThumb(
        i,
        positionDataThumb.x,
        positionDataThumb.y,
        positionDataThumb.screenrotation,
      );
      this.setFrameInfo(
        i,
        positionDataInfo.x,
        positionDataInfo.y,
        positionDataInfo.screenrotation,
      );
    }

    this.setInitialState();
  }

  prevNextHandler(direction) {
    let offset = 0;
    const maxOffset = this.raycaster.allFrames.length;

    if (this.frameContentIsAnimating) {
      return;
    }

    if (direction) {
      offset = direction === "next" ? 1 : -1;

      if (get(activePlayer) === 0 && offset === -1) {
        offset = maxOffset - 1;
      }

      if (get(activePlayer) === maxOffset - 1 && offset === 1) {
        offset = -maxOffset + 1;
      }

      this.activePlayer = this.raycaster.allFrames.find(
        (obj) => obj.playerId === get(activePlayer) + offset,
      );
    } else {
      this.activePlayer = this.raycaster.raycaster.currentHover?.object;

      if (!this.activePlayer) {
        return;
      }

      offset = get(activePlayer) - this.activePlayer.playerId * -1;

      if (get(activePlayer) === 0 && offset === -1) {
        offset = maxOffset - 1;
      }

      if (get(activePlayer) === maxOffset - 1 && offset === 1) {
        offset = -maxOffset + 1;
      }
    }

    // Send active player and state to store
    this.setActivePlayer(this.activePlayer);
    state.set(STATE.PLAYER_DETAIL_PREV_NEXT_ANIMATION_START);

    // Remove the video
    this.videoService.removeVideo(
      `video-player-${this.activePlayer.playerId - offset}`,
    );

    // Rotate to clicked frame
    gsap.to(this.camera.cameraContainer.rotation, {
      duration: 0.6,
      delay: 0,
      ease: "power1.inOut",
      y: this.camera.calculateRotation(this.activePlayer.position.clone()),
      onStart: () => {
        this.controls.panControls.clearInertia();
      },
      onUpdate: () => {
        this.controls.lookAroundControls.animateCamera();
      },
      onComplete: () => {
        this.controls.panControls.updateRotationAfterAnimation(
          this.camera.calculateRotation(this.activePlayer.position.clone()),
        );
        this.controls.scrollControls.updateRotationScroll();

        // Send state to store
        state.set(STATE.PLAYER_DETAIL);

        // On complete animation: play video
        this.setFrameVideo();
      },
    });
  }

  hoverToRotateHandler(direction) {
    this.updatedRotation = this.camera.cameraContainer.rotation.y;

    this.hoverToRotateAnimation = gsap.to(
      this.camera.cameraContainer.rotation,
      {
        repeat: -1,
        ease: "none",
        modifiers: {},
        onStart: () => {
          this.controls.panControls.clearInertia();
        },
        onUpdate: () => {
          this.updatedRotation += 0.004 * (direction === "left" ? 1 : -1);
          this.camera.cameraContainer.rotation.y = this.updatedRotation;
          this.controls.lookAroundControls.animateCamera();
        },
      },
    );
  }

  stopHoverToRotateHandler() {
    this.hoverToRotateAnimation.kill();
    this.controls.scrollControls.updateRotationScroll();
    this.controls.panControls.updateRotationAfterAnimation(
      this.updatedRotation,
    );
  }

  clickHandler(detailAlreadyActive) {
    this.activePlayer = this.raycaster.raycaster.currentHover?.object;

    if (!this.activePlayer) {
      return;
    }

    // Send active player and state to store
    this.setActivePlayer(this.activePlayer);
    state.set(STATE.PLAYER_DETAIL_ENTER_ANIMATION_START);

    // Rotate to clicked frame
    gsap.to(this.camera.cameraContainer.rotation, {
      duration: 0.4,
      delay: 0,
      ease: "power1.inOut",
      y: this.camera.calculateRotation(this.activePlayer.position.clone()),
      onStart: () => {
        this.controls.panControls.clearInertia();
      },
      onUpdate: () => {
        this.controls.lookAroundControls.animateCamera();
      },
      onComplete: () => {
        this.controls.panControls.updateRotationAfterAnimation(
          this.camera.calculateRotation(this.activePlayer.position.clone()),
        );
        this.controls.scrollControls.updateRotationScroll();
      },
    });

    if (detailAlreadyActive) {
      return;
    }

    // Set lookAroundControls factor
    gsap.to(this.controls.lookAroundControls, {
      duration: 1.4,
      delay: 0,
      ease: "power1.inOut",
      cameraFactorDesktop: LOOK_AROUND_CONTROLS_FACTOR_DETAIL_DESKTOP,
      cameraFactorMobile: LOOK_AROUND_CONTROLS_FACTOR_DETAIL_MOBILE,
    });

    // Zoom into clicked frame
    gsap.to(this.camera.instance, {
      duration: 1,
      delay: 0.4,
      ease: "power1.inOut",
      zoom: 2,
      onUpdate: () => {
        this.camera.instance.updateProjectionMatrix();
        this.controls.lookAroundControls.animateCamera();
      },
      onComplete: () => {
        // Send state to store
        state.set(STATE.PLAYER_DETAIL);

        // On complete animation: play video
        this.setFrameVideo();
      },
    });
  }

  exitHandler() {
    // Fade out the video
    gsap.to(this.videoMaterial.uniforms.opacity, {
      duration: FADE_DURATION,
      value: 0.0,
      ease: "power1.inOut",
      onUpdate: () => {
        this.videoMaterial.needsUpdate = true;
      },
      onComplete: () => {
        this.resetAllFrames();

        // Fade in the image
        gsap.to(this.activePlayer.material.uniforms.opacity, {
          duration: FADE_DURATION,
          value: 1.0,
          ease: "power1.inOut",
          onUpdate: () => {
            this.activePlayer.material.needsUpdate = true;
          },
        });
      },
    });

    // Zoom back out
    gsap.to(this.camera.instance, {
      duration: 1,
      delay: 0,
      ease: "power1.inOut",
      zoom: 1,
      onStart: () => {
        // Stop the playing video
        this.videoService.removeVideo(
          `video-player-${this.activePlayer.playerId}`,
        );

        // Reset activePlayer in store
        activePlayer.set(false);

        // Send state to store
        state.set(STATE.PLAYER_DETAIL_LEAVE_ANIMATION_START);
      },
      onUpdate: () => {
        this.camera.instance.updateProjectionMatrix();
        this.controls.lookAroundControls.animateCamera();
      },
      onComplete: () => {
        // Send state to store
        state.set(STATE.FIRST_PERSON_ACTIVE);
      },
    });

    // Set lookAroundControls factor
    gsap.to(this.controls.lookAroundControls, {
      duration: 1,
      delay: 0,
      ease: "power1.inOut",
      cameraFactorDesktop: LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP,
      cameraFactorMobile: LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_MOBILE,
    });
  }

  updateShortlist(playerId, addToShortlist, changeMaterial) {
    const shortlistedScreen = this.scene.children.find((obj) => {
      return obj.name.includes("ScreenPlane") && obj.playerId === playerId;
    });
    const shortlistedFrameDeco = this.scene.scene.children.find((obj) => {
      return obj.name === `frame_deco_${playerId}`;
    });

    // Set the material for the active player
    if (changeMaterial) {
      shortlistedScreen.material = addToShortlist
        ? shortlistedScreen.materialColorGold
        : shortlistedScreen.materialColor;
    }

    shortlistedScreen.material.uniforms.opacity.value = 1.0;

    const goldMaterial = new THREE.MeshBasicMaterial({
      color: "#BEB08C",
      wireframe: false,
      wireframeLinewidth: 2,
    });

    if (addToShortlist) {
      shortlistedFrameDeco.originalMaterial = shortlistedFrameDeco.material;
      shortlistedFrameDeco.material = goldMaterial;
    } else {
      shortlistedFrameDeco.material = shortlistedFrameDeco.originalMaterial;
    }
  }

  addToShortlist(playerId, changeMaterial) {
    this.updateShortlist.call(this, playerId, true, changeMaterial);
  }

  removeFromShortlist(playerId, changeMaterial) {
    this.updateShortlist.call(this, playerId, false, changeMaterial);
  }

  setActivePlayer(clickedPlayer) {
    activePlayer.set(clickedPlayer.playerId);

    this.allFrames = this.scene.children.filter((obj) => {
      return obj.name.includes("ScreenPlane");
    });

    for (let i = 0; i < this.allFrames.length; i++) {
      const object = this.allFrames[i];

      setTimeout(() => {
        if (object instanceof THREE.Mesh && object !== clickedPlayer) {
          this.players[i].active = false;
          object.material = object.materialBlackWhite;

          // fade in the frame content
          if (object.material.uniforms.opacity.value !== 1) {
            object.material.uniforms.opacity.value = 1.0;
            // gsap.to(object.material.uniforms.opacity, {
            //   duration: FADE_DURATION,
            //   value: 1.0,
            //   ease: "power1.inOut",
            //   onUpdate: () => {
            //     object.material.needsUpdate = true;
            //   },
            // })
          }
        } else {
          this.players[i].active = true;
        }
      });
    }
  }

  resetAllFrames() {
    this.allFrames.forEach((player) => {
      if (get(shortlistedPlayers).includes(player.playerId)) {
        player.material = player.materialColorGold;
      } else {
        player.material = player.materialColor;
      }

      player.material.uniforms.opacity.value = 1.0;
    });
  }

  setInitialState() {
    const frameDecoGold = this.scene.scene.children.filter((obj) => {
      return obj.name.includes("frame_deco_for_shadow_gold");
    });

    frameDecoGold.forEach((frameDeco) => {
      frameDeco.visible = false;
    });
  }

  setFrameVideo() {
    // Setup video texture
    this.videoService.setVideo(
      `video-player-${this.activePlayer.playerId}`,
      this.players[this.activePlayer.playerId].video,
    );
    this.videoElement = document.getElementById(
      `video-player-${this.activePlayer.playerId}`,
    );
    this.videoTexture = new THREE.VideoTexture(this.videoElement);
    this.videoTexture.colorSpace = THREE.SRGBColorSpace;
    this.videoTexture.flipY = true;

    // Shader to combine the video and the alphaMap
    this.videoMaterial = new THREE.ShaderMaterial({
      // color: 'red',
      transparent: 1, // 1 instead of true, fixes visual bug
      uniforms: {
        videoTexture: { value: this.videoTexture },
        alphaMap: { value: this.resources.items.frameVideoOverlayNew },
        opacity: { value: 0.0 }, // Default opacity
      },
      vertexShader: frameVideoVertexShader,
      fragmentShader: frameVideoFragmentShader,
    });

    // Fade out the image
    gsap.to(this.activePlayer.material.uniforms.opacity, {
      duration: FADE_DURATION,
      value: 0.0,
      ease: "power1.inOut",
      onStart: () => {
        this.frameContentIsAnimating = true;
      },
      onUpdate: () => {
        this.activePlayer.material.needsUpdate = true;
      },
      onComplete: () => {
        this.activePlayer.material = this.videoMaterial;
      },
    });

    // And fade in the video
    gsap.to(this.videoMaterial.uniforms.opacity, {
      duration: FADE_DURATION,
      delay: FADE_DURATION,
      value: 1.0,
      ease: "power1.inOut",
      onUpdate: () => {
        this.videoMaterial.needsUpdate = true;
      },
      onComplete: () => {
        this.frameContentIsAnimating = false;
      },
    });
  }

  setFrameThumb(i, x, y, rotation) {
    // Setup Geometry and Material
    this.screenGeometry = new THREE.PlaneGeometry(10, 10);
    this.screenMaterialColor = new THREE.ShaderMaterial({
      transparent: 1, // 1 instead of true, fixes visual bug
      uniforms: {
        texture1: { value: this.resources.items.backdropWhite },
        texture2: {
          value: this.resources.items[`player-${this.players[i].id}`],
        },
        opacity: { value: 1.0 }, // Default opacity
      },
      vertexShader: frameThumbVertexShader,
      fragmentShader: frameThumbFragmentShader,
    });

    this.screenMaterialColorGold = new THREE.ShaderMaterial({
      transparent: 1, // 1 instead of true, fixes visual bug
      uniforms: {
        texture1: { value: this.resources.items.backdropGold },
        texture2: {
          value: this.resources.items[`player-${this.players[i].id}`],
        },
        opacity: { value: 1.0 }, // Default opacity
      },
      vertexShader: frameThumbVertexShader,
      fragmentShader: frameThumbFragmentShader,
    });

    this.screenMaterialBlackWhite = new THREE.ShaderMaterial({
      transparent: 1, // 1 instead of true, fixes visual bug
      uniforms: {
        texture1: { value: this.resources.items.backdropWhite },
        texture2: {
          value: this.resources.items[`player-${this.players[i].id}`],
        },
        opacity: { value: 1.0 }, // Default opacity
      },
      vertexShader: inactivePlayerVertexShader,
      fragmentShader: inactivePlayerFragmentShader,
    });

    // Setup Mesh
    this.screenMesh = new THREE.Mesh(
      this.screenGeometry,
      this.screenMaterialColor,
    );
    this.screenMesh.name = `ScreenPlane${i}`;
    this.screenMesh.playerId = i;
    this.screenMesh.rotation.set(0, rotation, 0);
    this.screenMesh.position.set(x, 9, y);
    this.screenMesh.materialColor = this.screenMaterialColor;
    this.screenMesh.materialColorGold = this.screenMaterialColorGold;
    this.screenMesh.materialBlackWhite = this.screenMaterialBlackWhite;

    // Add Mesh to the Scene
    this.scene.add(this.screenMesh);

    // Add the screen plane to [players]
    this.players[i].plane = this.screenMesh;
  }

  setFrameInfo(i, x, y, rotation) {
    // Setup canvas
    this.frameInfoCanvas = document.createElement("canvas");
    this.frameInfoCanvasCtx = this.frameInfoCanvas.getContext("2d");
    this.frameInfoCanvas.width = 896;
    this.frameInfoCanvas.height = 256;
    this.frameInfoCanvasCtx.fillStyle = "rgba(0, 0, 0, 0)";
    this.frameInfoCanvasCtx.fillRect(
      0,
      0,
      this.frameInfoCanvas.width,
      this.frameInfoCanvas.height,
    );
    this.frameInfoCanvasCtx.fillStyle = "rgba(255, 255, 255, 1)";
    this.frameInfoCanvasCtx.font = "60px font-aeonik";
    this.frameInfoCanvasCtx.fillText(
      this.players[i].firstname,
      this.frameInfoCanvas.width * 0.2,
      50,
    );
    this.frameInfoCanvasCtx.fillStyle = "rgba(190, 176, 140, 1)";
    this.frameInfoCanvasCtx.font = "bold 80px font-aeonik";
    this.frameInfoCanvasCtx.fillText(
      this.players[i].lastname,
      this.frameInfoCanvas.width * 0.2,
      160,
    );

    // Setup Geometry and Material
    this.frameInfoGeometry = new THREE.PlaneGeometry(7, 2);
    this.frameInfoTexture = new THREE.CanvasTexture(this.frameInfoCanvas);
    this.frameInfoTexture.colorSpace = THREE.SRGBColorSpace;
    this.frameInfoMaterial = new THREE.MeshBasicMaterial({
      map: this.frameInfoTexture,
      transparent: true,
    });

    // Setup Mesh
    this.frameInfo = new THREE.Mesh(
      this.frameInfoGeometry,
      this.frameInfoMaterial,
    );
    this.frameInfo.name = "frameInfo";
    this.frameInfo.rotation.set(0, rotation, 0);
    this.frameInfo.position.set(x, 3.5, y);

    // Add Mesh to scene
    this.scene.add(this.frameInfo);
  }
}
