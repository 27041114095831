import {
  removeStorageItem,
  saveStorageItem,
  STORAGE_KEYS,
} from "./storageService.js";
import { user } from "../../store/user.js";
import { get } from "svelte/store";
import { getPlayersByShortlist } from "./shortlistService.js";
import { getEnvironment } from "./environmentService.js";

export const saveUserFavorites = async () => {
  const token = JSON.parse(localStorage.getItem(STORAGE_KEYS.UNIDY_TOKEN));

  if (!token || !token?.access_token) {
    return;
  }

  const userData = get(user);

  const formData = new FormData();
  formData.append("userId", userData.sub);
  formData.append(
    "customAttributes[players]",
    getPlayersByShortlist()
      .map((player) => player.firstname + " " + player.lastname)
      .join(","),
  );
  formData.append("accessToken", token.access_token);

  const result = await fetch(
    `/api-simple/?module=unidy&action=update-user-custom-attributes`,
    {
      method: "POST",
      body: formData,
      redirect: "follow",
    },
  );

  const response = await result.json();

  return response.status === "success";
};

export const authorizeToken = async () => {
  const urlData = new URL(window.location.href);
  const fragment = urlData.hash;

  if (fragment.substring(0, 1) !== "#") {
    return;
  }

  const params = new URLSearchParams(fragment.substring(1));
  const code = params.get("code");

  if (!code) {
    return;
  }

  const formData = new FormData();
  formData.append("code", code);

  const result = await fetch(`/api-simple/?module=unidy&action=authorize`, {
    method: "POST",
    body: formData,
    redirect: "follow",
  });

  const response = await result.json();

  if (response.status !== "success" || !response.data?.token?.access_token) {
    return;
  }

  saveStorageItem(STORAGE_KEYS.UNIDY_TOKEN, response.data.token);

  window.location.href =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
};

export const getSsoUrl = () => {
  let ssoUrl = "https://proleague.staging.unidy.de";
  let clientId = "9BOYJQLJmdXoROWrW07fWzqzk-pLgOawxB3NiBmqOQc";

  if (getEnvironment() === "production") {
    ssoUrl = "https://proleague.unidy.de";
    clientId = "0RXEuNSg18_BuwuyIWj9vy2k2oKhz0ncnIgySBPek5Y";
  }

  return `${ssoUrl}/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(window.location.origin)}&scope=custom_attributes:write+openid+email+profile&response_type=code&response_mode=fragment`;
};

export const getUserInfo = async () => {
  const userSession = JSON.parse(sessionStorage.getItem("user"));

  if (userSession) {
    user.set(userSession);
    return;
  }

  const token = JSON.parse(localStorage.getItem(STORAGE_KEYS.UNIDY_TOKEN));

  if (!token || !token?.access_token) {
    return;
  }

  const formData = new FormData();
  formData.append("accessToken", token.access_token);

  const result = await fetch(`/api-simple/?module=unidy&action=userinfo`, {
    method: "POST",
    body: formData,
    redirect: "follow",
  });

  const response = await result.json();

  if (response.status !== "success") {
    return;
  }

  user.set(response.data.user);
  sessionStorage.setItem("user", JSON.stringify(response.data.user));
};

export const refreshAccessToken = async () => {
  const token = JSON.parse(localStorage.getItem(STORAGE_KEYS.UNIDY_TOKEN));

  if (!token || !token?.access_token) {
    return;
  }

  const dateNow = new Date();
  const dateCreatedAt = new Date(token.created_at * 1000);
  dateCreatedAt.setSeconds(dateCreatedAt.getSeconds() + token.expires_in);

  if (dateNow < dateCreatedAt) {
    return;
  }

  const formData = new FormData();
  formData.append("refreshToken", token.refresh_token);

  const result = await fetch(
    `/api-simple/?module=unidy&action=refresh-access-token`,
    {
      method: "POST",
      body: formData,
      redirect: "follow",
    },
  );

  const response = await result.json();

  if (response.status !== "success" || !response.data?.token?.access_token) {
    removeStorageItem(STORAGE_KEYS.UNIDY_TOKEN);
    user.set(null);
    return;
  }

  saveStorageItem(STORAGE_KEYS.UNIDY_TOKEN, response.data.token);
};
