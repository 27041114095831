<script>
	import {updateShortlist} from '../services/shortlistService.js';
    import {translate} from '../../translations/index.js';
    import {fade} from 'svelte/transition';
    import {get} from 'svelte/store';
    import {shortlistedPlayers} from '../../store/shortlistedPlayers.js';
    import {REQUIRED_PLAYER_AMOUNT} from '../../Experience/config.js';
    import Svg from './Svg.svelte';

    export let confirm = false;
    export let player;

    let shortlistFull = false;

    const favorite = () => {
      if (get(shortlistedPlayers).length === REQUIRED_PLAYER_AMOUNT && !get(shortlistedPlayers).includes(player.id)) {
        shortlistFull = true;

        setTimeout(() => {
          shortlistFull = false;
        }, 3000)
      } else {
        updateShortlist(player.id, true)
      }
    }
</script>

<div class="player" class:active={player.shortlist} class:confirm={confirm}>
	<div class="player__image-wrapper">
		<img class="player__image" src="{player.imageUrl}" alt={player.name}>
	</div>

	<div class="player__info">
		<div class="player__name">
			<span>{player.firstname}</span>
			<span class="player__name__last">{player.lastname}</span>
		</div>
		<div class="player__meta">
			<div class="player__club">
				<img class="player__club__logo" src="{player.logoUrl}" alt="{player.club}">
				<span>{player.club}</span>
			</div>

			<span>{player.games} {translate('hof.player.matches')}</span>
		</div>
	</div>

	{#if !confirm}
	<button class="player__star" on:click={() => favorite()}>
		 <Svg
			 icon="icon-heart-filled"
			 width={24}
			 height={24}
		 />
	</button>
	{/if}

	{#if shortlistFull}
		<div class="alert" transition:fade={{ duration: 400,  }}>{translate('hof.players.max.1')} {REQUIRED_PLAYER_AMOUNT} {translate('hof.players.max.2')}</div>
	{/if}
</div>

<style>
  .player {
    position: relative;
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 12px 0;
    box-sizing: border-box;
	border-bottom: 1px solid var(--color-white-20);
  }

  .player__image-wrapper {
	position: relative;
	display: flex;
  }

  .player.active .player__image-wrapper:after {
    content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(/images/misc/active-player.png);
	background-size: cover;
  }

  .player__image {
    height: 80px;
    width: 64px;
    object-fit: cover;
    object-position: top center;
    flex-shrink: 0;
  }

  .player__info {
    display: flex;
    flex-direction: column;
	gap: 4px;
    color: var(--color-white);
  }

  .player__name {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
  }

  .player__name__last {
    font-weight: 500;
  }

  .player__meta {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.2;
  }

  .player__club {
	display: flex;
	align-items: center;
	gap: 4px;
	font-weight: 500;
  }

  .player__club__logo {
	height: 16px;
	width: 16px;
    object-fit: contain;
  }

  .player__star {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-left: auto;
  }

  :global(.player__star svg) {
	fill: transparent;
	stroke: var(--color-white);
	stroke-width: 2px;
  }

  :global(.player.active .player__star svg),
  :global(.player__star:hover svg) {
    fill: var(--color-gold);
    stroke: var(--color-gold);
  }

  .alert {
    position: absolute;
    right: 0;
    top: 8px;
    color: var(--color-white);
    z-index: 1;
    font-size: 12px;
    border-radius: 0;
  }

  .player.confirm .player__info {
	color: var(--color-black);
  }
</style>
