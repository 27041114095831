<script>
  import {fade} from 'svelte/transition';
  import {translate} from '../../translations/index.js';
  import {DotLottieSvelte} from '@lottiefiles/dotlottie-svelte';
  import MobileAndTabletCheck from '../../../src/Experience/Utils/MobileAndTabletCheck.js'

  const check = new MobileAndTabletCheck().check()
</script>

<div class="hint" transition:fade={{ duration: 300 }}>

	{#if check}
		<div class="hint__lottie">
			<DotLottieSvelte
					src="https://lottie.host/1917721e-4d34-4b91-b087-d4cd82f92608/KCvYXymjIk.json"
					loop
					autoplay
			/>
		</div>
		<span>{translate('hof.hint.swipe')}</span>
	{:else}
		<div class="hint__lottie">
			<DotLottieSvelte
					src="https://lottie.host/26a0cdf8-4894-4ac8-a99d-0376d10ab998/2HnX4Bx3Ll.json"
					loop
					autoplay
			/>
		</div>
		<span>{translate('hof.hint.scroll')}</span>
	{/if}
</div>

<style>
  .hint {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    max-width: 240px;
    color: var(--color-white);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.1;
  }

  .hint__lottie {
    width: 96px;
  }

  @media screen and (min-width: 640px) {
    .hint {
      max-width: 100%;
      font-size: 14px;
    }

    .hint__lottie {
      width: 120px;
    }
  }
</style>
