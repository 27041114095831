<script>
  import { fade } from 'svelte/transition';
  import {state} from '../../store/state.js';
  import {ONBOARDING_STATES, STATE} from '../../Experience/config.js';
  import Loader from './Loader.svelte';
  import LangSelector from './LangSelector.svelte';
  import Start from './Start.svelte';
  import Partners from './Partners.svelte';

  let spinnerComplete = false;
</script>

{#if ONBOARDING_STATES.includes($state)}
	<div class="onboarding" class:loaded={spinnerComplete} out:fade={{ duration: 400 }}>
		{#if $state === STATE.LOADING || $state === STATE.LOADING_COMPLETE}
			<div class="item" in:fade={{ delay: 450, duration: 400 }} out:fade={{ duration: 400 }}>
				<Loader bind:spinnerComplete />
			</div>
		{/if}
		{#if $state === STATE.LANG_SELECTOR}
			<div class="item" in:fade={{ delay: 450, duration: 400 }} out:fade={{ duration: 400 }}>
				<LangSelector/>
			</div>
		{/if}
		{#if $state === STATE.LANG_SELECTED}
			<div class="item" in:fade={{ delay: 450, duration: 400 }} out:fade={{ duration: 400 }}>
				<Start/>
			</div>
		{/if}
		{#if $state === STATE.LANG_SELECTOR || $state === STATE.LANG_SELECTED}
			<div in:fade={{ delay: 450, duration: 400 }} out:fade={{ duration: 400 }}>
				<Partners />
			</div>
		{/if}
	</div>
{/if}

<style>
	.onboarding {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      background-color: var(--color-black);
	  pointer-events: all;
	}

	.onboarding.loaded {
      background-color: var(--color-black-60);
	  transition: background-color 0.4s ease 0.6s;
	}

	.item {
      position: absolute;
      top: 0;
      left: 0;
	  z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
	}
</style>
