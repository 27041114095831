export const SKIP_INTRO_WALL_ANIMATION = true;
export const SKIP_INTRO_CAMERA_ANIMATION = false;
export const INTRO_ANIMATION_DURATION = 2;
export const FADE_DURATION = 0.6;
export const REQUIRED_PLAYER_AMOUNT = 3;

export const INTRO_ANIMATION_END_POSITION_X = 0;
export const INTRO_ANIMATION_END_POSITION_Y = 9;
export const INTRO_ANIMATION_END_POSITION_Z = 0;

export const CAMERA_START_POSITION_X = SKIP_INTRO_CAMERA_ANIMATION
  ? INTRO_ANIMATION_END_POSITION_X
  : 0;
export const CAMERA_START_POSITION_Y = SKIP_INTRO_CAMERA_ANIMATION
  ? INTRO_ANIMATION_END_POSITION_Y
  : 7500;
export const CAMERA_START_POSITION_Z = SKIP_INTRO_CAMERA_ANIMATION
  ? INTRO_ANIMATION_END_POSITION_Z
  : 0;

export const LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP = 0.4;
export const LOOK_AROUND_CONTROLS_FACTOR_DETAIL_DESKTOP = 0.05;

export const LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_MOBILE = 0.006;
export const LOOK_AROUND_CONTROLS_FACTOR_DETAIL_MOBILE = 0.002;

export const STATE = {
  LOADING: "loading",
  LOADING_COMPLETE: "loadingComplete",
  INTRO_ZOOM: "introZoom",
  LANG_SELECTOR: "langSelector",
  LANG_SELECTED: "langSelected",
  EXPERIENCE_START: "experienceStart",
  INTRO_ANIMATION_START: "introAnimationStart",
  INTRO_ANIMATION_END: "introAnimationEnd",
  FIRST_PERSON_ACTIVE: "firstPersonActive",
  FIRST_PERSON_ACTIVE_AND_HOVERING: "firstPersonActiveAndHovering",
  FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO: "firstPersonActiveAndHoveringVideo",
  FIRST_PERSON_NOT_ACTIVE: "firstPersonViewNotActive",
  PLAYER_DETAIL_PREV_NEXT_ANIMATION_START: "playerDetailPrevNextAnimationStart",
  PLAYER_DETAIL_ENTER_ANIMATION_START: "playerDetailEnterAnimationStart",
  PLAYER_DETAIL: "playerDetail",
  PLAYER_DETAIL_LEAVE_ANIMATION_START: "playerDetailLeaveAnimationStart",
  VIDEO_DETAIL_ENTER_ANIMATION_START: "videoDetailEnterAnimationStart",
  VIDEO_DETAIL: "videoDetail",
  VIDEO_DETAIL_LEAVE_ANIMATION_START: "videoDetailLeaveAnimationStart",
  SHARE: "share",
  SHORTLIST_ACTIVE: "shortlistActive",
  SHORTLIST_CONFIRM: "shortlistConfirm",
  SHORTLIST_SAVE: "shortlistSave",
};

export const LOADING_STATES = [
  STATE.LOADING,
  STATE.LOADING_COMPLETE,
  STATE.INTRO_ZOOM,
];

export const ONBOARDING_STATES = [
  STATE.LOADING,
  STATE.LOADING_COMPLETE,
  STATE.INTRO_ZOOM,
  STATE.LANG_SELECTOR,
  STATE.LANG_SELECTED,
];

export const HOVER_STATES = [
  STATE.FIRST_PERSON_ACTIVE_AND_HOVERING,
  STATE.FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO,
];

export const LOOK_AROUND_STATES = [
  STATE.INTRO_ANIMATION_END,
  STATE.FIRST_PERSON_ACTIVE,
  STATE.FIRST_PERSON_ACTIVE_AND_HOVERING,
  STATE.FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO,
  STATE.FIRST_PERSON_NOT_ACTIVE,
  STATE.PLAYER_DETAIL_PREV_NEXT_ANIMATION_START,
  STATE.PLAYER_DETAIL_ENTER_ANIMATION_START,
  STATE.PLAYER_DETAIL,
  STATE.PLAYER_DETAIL_LEAVE_ANIMATION_START,
  STATE.VIDEO_DETAIL_ENTER_ANIMATION_START,
  STATE.VIDEO_DETAIL,
  STATE.VIDEO_DETAIL_LEAVE_ANIMATION_START,
];
