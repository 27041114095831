import * as THREE from "three";
import Experience from "./Experience.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import colorOverlayVertexShader from "./Shaders/color-overlay/vertex.glsl";
import colorOverlayFragmentShader from "./Shaders/color-overlay/fragment.glsl";
import vignetteOverlayVertexShader from "./Shaders/vignette-overlay/vertex.glsl";
import vignetteOverlayFragmentShader from "./Shaders/vignette-overlay/fragment.glsl";

export default class Renderer {
  constructor() {
    this.experience = new Experience();
    this.canvas = this.experience.canvas;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;

    this.setInstance();
  }

  setInstance() {
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
      premultipliedAlpha: false,
    });
    // this.instance.toneMapping = THREE.NoToneMapping
    // this.instance.colorSpace = THREE.LinearSRGBColorSpace
    this.instance.outputColorSpace = THREE.SRGBColorSpace;
    this.instance.outputEncoding = THREE.sRGBEncoding;

    // this.instance.toneMapping = THREE.CineonToneMapping
    // this.instance.toneMappingExposure = 1.75
    // this.instance.outputEncoding = THREE.sRGBEncoding;
    // this.instance.gammaOutput = true;
    // this.instance.gammaFactor = 2.2;

    this.instance.setClearColor("#000");
    this.instance.setSize(this.sizes.width, this.sizes.height);
    this.instance.setPixelRatio(this.sizes.pixelRatio);

    // Effect composer
    this.effectComposer = new EffectComposer(this.instance);
    this.effectComposer.setSize(this.sizes.width, this.sizes.height);
    this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Render pass
    this.renderPass = new RenderPass(this.scene, this.camera.instance);
    this.effectComposer.addPass(this.renderPass);

    // Color overlay pass
    this.colorOverlayShader = {
      uniforms: {
        tDiffuse: { value: null },
        opacity: { value: 0.25 },
        overlayColor: { value: new THREE.Color(0x16002b) },
      },
      vertexShader: colorOverlayVertexShader,
      fragmentShader: colorOverlayFragmentShader,
    };
    this.colorOverlayPass = new ShaderPass(this.colorOverlayShader);
    this.effectComposer.addPass(this.colorOverlayPass);

    // Vignette overlay pass
    this.vignetteOverlayShader = {
      uniforms: {
        tDiffuse: { value: null },
        offset: { value: 0.9 },
        darkness: { value: 1.5 },
        vignetteColor: { value: new THREE.Color("#16002b") },
      },
      vertexShader: vignetteOverlayVertexShader,
      fragmentShader: vignetteOverlayFragmentShader,
    };
    this.vignetteOverlayPass = new ShaderPass(this.vignetteOverlayShader);
    this.effectComposer.addPass(this.vignetteOverlayPass);

    // Noise overlay pass
    const textureLoader = new THREE.TextureLoader();
    const overlayTexture = textureLoader.load("/images/misc/noise.png");
    this.noiseShader = {
      uniforms: {
        tDiffuse: { value: null },
        overlayTexture: { value: overlayTexture },
        imageSize: { value: new THREE.Vector2(200, 200) }, // size of each image
        resolution: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform sampler2D overlayTexture;
        uniform vec2 imageSize;
        uniform vec2 resolution;
        varying vec2 vUv;
        
        void main() {
          vec4 baseColor = texture2D(tDiffuse, vUv);
          vec2 scaledUv = mod(vUv * resolution / imageSize, 1.0);
          vec4 overlayColor = texture2D(overlayTexture, scaledUv);
        
          // Blend the overlay color with the base color based on the overlay alpha
          vec4 finalColor = mix(baseColor, overlayColor, overlayColor.a);
          gl_FragColor = vec4(finalColor.rgb, 1.0); // Ensure full opacity
        }
      `,
    };
    this.noisePass = new ShaderPass(this.noiseShader);
    this.effectComposer.addPass(this.noisePass);

    //Gamma Correction pass
    this.gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
    this.effectComposer.addPass(this.gammaCorrectionPass);

    // let fxaaPass = new ShaderPass( FXAAShader );
    // fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( this.sizes.width * this.sizes.pixelRatio );
    // fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( this.sizes.height * this.sizes.pixelRatio );
    // this.effectComposer.addPass(fxaaPass);

    // this.smaaPass = new SMAAPass()
    // this.effectComposer.addPass(this.smaaPass);

    // // Create the FXAA pass
    // const pixelRatio = this.instance.getPixelRatio();
    // this.FXAAPass = new ShaderPass(FXAAShader);
    // this.FXAAPass.material.uniforms['resolution'].value.x = 1 / (this.sizes.width * pixelRatio);
    // this.FXAAPass.material.uniforms['resolution'].value.y = 1 / (this.sizes.height * pixelRatio);
    // this.effectComposer.addPass(this.FXAAPass);

    //     Custom noise shader
    //     const shader = `
    // uniform float iTime;
    // uniform vec2 iResolution;
    // uniform sampler2D tDiffuse;
    //
    // #define SHOW_NOISE 0
    // #define SRGB 0
    // #define BLEND_MODE 0
    // #define SPEED 0.1
    // #define INTENSITY 0.05
    // #define MEAN 0.0
    // #define VARIANCE 0.5
    //
    // vec3 channel_mix(vec3 a, vec3 b, vec3 w) {
    //     return vec3(mix(a.r, b.r, w.r), mix(a.g, b.g, w.g), mix(a.b, b.b, w.b));
    // }
    //
    // float gaussian(float z, float u, float o) {
    //     return (1.0 / (o * sqrt(2.0 * 3.1415))) * exp(-(((z - u) * (z - u)) / (2.0 * (o * o))));
    // }
    //
    // vec3 screen(vec3 a, vec3 b, float w) {
    //     return mix(a, vec3(1.0) - (vec3(1.0) - a) * (vec3(1.0) - b), w);
    // }
    //
    // vec3 overlay(vec3 a, vec3 b, float w) {
    //     return mix(a, channel_mix(
    //         2.0 * a * b,
    //         vec3(1.0) - 2.0 * (vec3(1.0) - a) * (vec3(1.0) - b),
    //         step(vec3(0.5), a)
    //     ), w);
    // }
    //
    // vec3 soft_light(vec3 a, vec3 b, float w) {
    //     return mix(a, pow(a, pow(vec3(2.0), 2.0 * (vec3(0.5) - b))), w);
    // }
    //
    // void main() {
    //     vec2 uv = gl_FragCoord.xy / iResolution.xy;
    //     vec4 color = texture2D(tDiffuse, uv);
    //
    //     #if SRGB
    //     color.rgb = pow(color.rgb, vec3(2.2));
    //     #endif
    //
    //     float t = iTime * float(SPEED);
    //     float seed = dot(uv, vec2(12.9898, 78.233));
    //     float noise = fract(sin(seed) * 43758.5453 + t);
    //     noise = gaussian(noise, float(MEAN), float(VARIANCE) * float(VARIANCE));
    //
    //     #if SHOW_NOISE
    //     color.rgb = vec3(noise);
    //     #else
    //     float w = float(INTENSITY);
    //     vec3 grain = vec3(noise);
    //
    //     #if BLEND_MODE == 0
    //     color.rgb += grain * w;
    //     #elif BLEND_MODE == 1
    //     color.rgb = screen(color.rgb, grain, w);
    //     #elif BLEND_MODE == 2
    //     color.rgb = overlay(color.rgb, grain, w);
    //     #elif BLEND_MODE == 3
    //     color.rgb = soft_light(color.rgb, grain, w);
    //     #elif BLEND_MODE == 4
    //     color.rgb = max(color.rgb, grain * w);
    //     #endif
    //
    //     #if SRGB
    //     color.rgb = pow(color.rgb, vec3(1.0 / 2.2));
    //     #endif
    //     #endif
    //
    //     gl_FragColor = color;
    // }
    //
    // `;
    //     this.grainShader = {
    //       uniforms: {
    //         iTime: {value: 0.0},
    //         iResolution: {value: new THREE.Vector2(window.innerWidth, window.innerHeight)}
    //       },
    //       vertexShader: `
    //     varying vec2 vUv;
    //     void main() {
    //       vUv = uv;
    //       gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    //     }
    //   `,
    //       fragmentShader: shader,
    //     };
    //     this.grainPass = new ShaderPass(this.grainShader);
    //     this.effectComposer.addPass(this.grainPass)
  }

  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.height);
    this.instance.setPixelRatio(this.sizes.pixelRatio);
  }

  update() {
    // this.grainPass.material.uniforms.iTime.value = performance.now() / 1000;

    this.instance.render(this.scene, this.camera.instance);
    // this.effectComposer.render()
  }
}
