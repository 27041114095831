import * as THREE from "three";
import Experience from "../Experience.js";

export default class HallOfFame {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // Resource
    this.resource = this.resources.items.hallOfFameModel;

    this.setModel();
  }

  setModel() {
    this.model = this.resource.scene;
    this.scene.add(this.model);

    this.resources.items.baked.flipY = false;
    this.bakedMaterial = new THREE.MeshBasicMaterial({
      map: this.resources.items.baked,
    });

    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (
          child.name === "Cylinder024" ||
          child.name === "Cylinder025" ||
          child.name === "Floor001" ||
          child.name === "logo_part_1001" ||
          child.name === "logo_part_1"
        ) {
          child.material = this.bakedMaterial;
        }
      }
    });
  }
}
