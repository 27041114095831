<script>
  import {pointer} from '../../store/pointer.js';
  import MobileAndTabletCheck from '../../Experience/Utils/MobileAndTabletCheck.js';
  import {state} from '../../store/state.js';
  import {STATE} from '../../Experience/config.js';

  let isHovering;
</script>

{#if !new MobileAndTabletCheck().check()}
	<div class="pointer pointer--visible"
		 class:pointer--hover={isHovering}
		 class:pointer--dark={$state === STATE.SHORTLIST_CONFIRM}
		 style={`transform: translate3d(${$pointer.location[0]}px, ${$pointer.location[1]}px, 0) translate(-50%, -50%);`}
	></div>
{/if}

<style>
  .pointer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    height: 40px;
    width: 40px;
    opacity: 1;
	background-color: var(--color-white-50);
    border-radius: 50%;
	transition: height 0.8s ease, width 0.8s ease, opacity 0.8s ease;
  }

  :global([data-pointer-hover="true"]) {
	cursor: pointer;
  }

  :global([data-pointer-hover="true"] .pointer) {
	opacity: 0;
    height: 0;
    width: 0;
  }

  .pointer--dark {
	background-color: var(--color-black-20);
  }
</style>
