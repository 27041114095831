import * as THREE from "three";
import Experience from "../Experience.js";

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("environment");
    }

    this.setSunLight();
  }

  setSunLight() {
    this.sunLight = new THREE.PointLight("#ffffff", 1, 0, 0);
    this.sunLight.position.set(0, 30, 0);
    this.scene.add(this.sunLight);

    // this.sunLight2 = new THREE.PointLight('#ffffff', 2, 100, 0)
    // this.sunLight2.position.set(0, 200, 0)
    // this.scene.add(this.sunLight2)

    // const pointLightHelper = new THREE.PointLightHelper( this.sunLight, 1 );
    // this.scene.add( pointLightHelper );
    //
    // const pointLightHelper2 = new THREE.PointLightHelper( this.sunLight2, 1 );
    // this.scene.add( pointLightHelper2 );

    // Debug
    if (this.debug.active) {
      this.debugFolder
        .add(this.sunLight, "intensity")
        .name("sunLightIntensity")
        .min(0)
        .max(10)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "x")
        .name("sunLightX")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "y")
        .name("sunLightY")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "z")
        .name("sunLightZ")
        .min(-5)
        .max(5)
        .step(0.001);
    }
  }
}
