import Experience from "./../Experience.js";

import * as THREE from "three";

export default class ScrollControls {
  constructor() {
    this.experience = new Experience();
    this.camera = this.experience.camera;
    this.scene = this.experience.scene;

    // Scroll controls variables
    this.scrollDistance = 0;
    this.scrollFactor = 20000;
  }

  scrollHandler(e) {
    this.scrollDistance += e.wheelDeltaY / this.scrollFactor;
    this.camera.cameraContainer.rotation.y =
      this.scrollDistance * Math.PI - Math.PI;
    this.camera.instance.updateMatrixWorld();
  }

  updateRotationScroll() {
    this.scrollDistance =
      (this.camera.cameraContainer.rotation.y + Math.PI) / Math.PI;
  }
}
