import { get } from "svelte/store";
import { players } from "../../store/players";
import { shortlistedPlayers } from "../../store/shortlistedPlayers";
import { REQUIRED_PLAYER_AMOUNT } from "../../Experience/config.js";

const getPlayersByShortlist = () => {
  const allPlayers = get(players);
  const allshortlistedPlayers = get(shortlistedPlayers);
  const filteredPlayers = allPlayers.filter((player) =>
    allshortlistedPlayers.includes(player.id),
  );

  filteredPlayers.sort((a, b) => {
    const lastNameComparison = a.lastname.localeCompare(b.lastname);

    if (lastNameComparison !== 0) {
      return lastNameComparison;
    }

    return a.lastname.localeCompare(b.lastname);
  });

  return filteredPlayers;
};

const updateShortlist = (id, changeMaterial = false) => {
  const allPlayers = get(players);
  const allshortlistedPlayers = get(shortlistedPlayers);
  const player = allPlayers.find((obj) => obj.id === id);

  if (player.shortlist === true) {
    player.shortlist = false;
    allshortlistedPlayers.splice(allshortlistedPlayers.indexOf(id), 1);
    window.Frame.removeFromShortlist(id, changeMaterial);
  } else if (allshortlistedPlayers.length < REQUIRED_PLAYER_AMOUNT) {
    player.shortlist = true;
    allshortlistedPlayers.push(id);
    window.Frame.addToShortlist(id, changeMaterial);
  }

  players.set(allPlayers);
  shortlistedPlayers.set(allshortlistedPlayers);
};

export { getPlayersByShortlist, updateShortlist };
