uniform sampler2D videoTexture;
uniform sampler2D alphaMap;

varying vec2 vUv;

void main() {
    vec4 videoColor = texture2D(videoTexture, vUv);
    vec4 alphaMapColor = texture2D(alphaMap, vUv);
    vec3 mixedColor = mix(videoColor.rgb, alphaMapColor.rgb, alphaMapColor.a);

    gl_FragColor = vec4(mixedColor, 1.0);
}
