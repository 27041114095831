export const hallOfFame = {
  "hof.start.title": "Which players deserve a place in the Hall of Fame?",
  "hof.start.btn": "Select your 3 legends",
  "hof.start.text": "Voting open until 16 October",
  "hof.start.message":
    "On this page we use sensors in your phone, and you have not granted us access to the sensors. If you want to change your choice, close this page and reopen it. Click 'Allow' when the request appears.",

  "hof.players.activeplayer.remove": "Remove from your legends",
  "hof.players.activeplayer.add": "Add to your legends",
  "hof.players.activeplayer.close": "Close",

  "hof.players.favorites": "legends",
  "hof.players.all": "Shortlist",
  "hof.players.players": "Shortlist",
  "hof.players.empty": "No legends selected yet",
  "hof.players.submit": "Submit your legends",
  "hof.players.max.1": "You already have",
  "hof.players.max.2": "legends selected!",

  "hof.player.matches": "mts.",

  "hof.shortlist-confirm.title": "Confirm your selection",
  "hof.shortlist-confirm.text":
    "Are you confident about your choice? Then confirm the three legends via your Pro League account.",
  "hof.shortlist-confirm.btn.confirm": "Continue",
  "hof.shortlist-confirm.btn.back": "Go back",

  "hof.share.title": "Your choice has been registered",
  "hof.share.text":
    "Thank you for voting for the Hall of Fame! We will induct ten Pro League Legends at the end of October. Share your selection with friends and family now.",
  "hof.share.btn.close": "Close",
  "hof.share.visual.title": "Share your choice:",
  "hof.share.visual.download.timeline": "Download (timeline) ",
  "hof.share.visual.download.story": "Download (story) ",
  "hof.share.visual.share.timeline": "Share (timeline)",
  "hof.share.visual.share.story": "Share (story)",
  "hof.share.visual.share.text": "My selection for the Pro League Hall of Fame",

  "hof.hint.scroll": "Scroll to rotate",
  "hof.hint.swipe": "Swipe to rotate",

  "hof.sound.on": "Sound on",
  "hof.sound.off": "Sound off",
};
