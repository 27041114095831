import Experience from "./../Experience.js";
import PanControls from "./PanControls.js";
import ScrollControls from "./ScrollControls.js";
import LookAroundControls from "./LookAroundControls.js";

export default class Controls {
  constructor() {
    this.experience = new Experience();
    this.panControls = new PanControls();
    this.scrollControls = new ScrollControls();
    this.lookAroundControls = new LookAroundControls();

    // Currently not in use, saved code as backup for now. If used:
    // - Make sure firstPersonControls.update() gets executed on update
    // - Make sure initFirstPersonControls() gets executed once
    // - Make sure to lock() and unlock() te controls at the right moment
    // - Find code for clickHandler() and exitHandler() in Frame.js on GitLab and apply
    // this.firstPersonControls = new FirstPersonControls()
  }
}
