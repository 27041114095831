<script>
  import {state} from '../../store/state.js';
  import {STATE} from '../../Experience/config.js';
  import {translate} from '../../translations/index.js';
  import Events from '../../Experience/Events.js';
  import MobileAndTabletCheck from '../../Experience/Utils/MobileAndTabletCheck.js';
  import {initControls} from '../services/controlsService.js';

  const isMobile = new MobileAndTabletCheck().check()
  const event = new Events()
  const lookAroundControls = window.experience.controls.lookAroundControls

  const clickStart = async () => {
    const goodToGo = await initControls();

    if (isMobile) {
      if (goodToGo) {
        state.set(STATE.EXPERIENCE_START)
      } else {
        alert(translate('hof.start.message'));
      }
    } else {
      state.set(STATE.EXPERIENCE_START)
    }

    setTimeout(() => {
      state.set(STATE.INTRO_ANIMATION_START)
    }, 1000)
  }
</script>

<div class="loading">
	<div class="wrapper-inner">
		<div class="loading__screen">
			<h1 class="loading__title">{translate('hof.start.title')}</h1>
			<p class="loading__text">{translate('hof.start.text')}</p>

			<button class="loading__button btn btn--large btn--primary" on:click={() => clickStart()}>
				<span class="btn__label">
					<span>{translate('hof.start.btn')}</span>
					<span>{translate('hof.start.btn')}</span>
				</span>
			</button>
		</div>
	</div>
</div>

<style>
  .loading {
    width: 100%;
  }

  .loading__screen {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
    box-sizing: border-box;
    pointer-events: all;
  }

  .loading__title {
    max-width: 880px;
    margin-bottom: 32px;
    color: var(--color-white);
    font-weight: 400;
    line-height: 1.1;
    font-size: 10vw;
  }

  .loading__text {
	color: var(--color-white);
	font-size: 3.5vw;
	line-height: 1.5;
	margin: 0;
  }

  .loading__button {
    margin-top: 32px;
  }

  @media screen and (min-width: 640px) {
    .loading__title {
      font-size: 72px;
    }

    .loading__text {
	  font-size: 20px;
	}

	.loading__button {
      margin-top: 72px;
	}
  }
</style>
