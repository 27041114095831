uniform float offset;
uniform float darkness;
uniform sampler2D tDiffuse;
uniform vec3 vignetteColor;
varying vec2 vUv;

void main() {
    vec4 texel = texture2D(tDiffuse, vUv);
    vec2 uv = (vUv - vec2(0.5)) * vec2(offset);
    float vignette = 1.0 - dot(uv, uv) * darkness;
    gl_FragColor = vec4(mix(texel.rgb, vignetteColor, 1.0 - vignette), texel.a);
}
