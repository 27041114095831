<script>
	export let position = 'top';
</script>

<div class="blurred-gradient blurred-gradient--{position}"></div>

<style>
  .blurred-gradient {
    position: fixed;
    z-index: 1; /* Make sure it's on top */
    height: 120px;
    top: 0;
    width: 100%;
    left: -16px;
  }

  .blurred-gradient:before {
    content: '';
    position: absolute;
    inset: 0 0 0 0; /* Apply blur to everything behind */
    backdrop-filter: blur(20px); /* For Safari support */
    mask-image: linear-gradient(to bottom, rgb(0 0 0 / 1) 0%, rgb(0 0 0 / 0) 100%);
  }

  :global(.blurred-gradient--bottom) {
	top: unset !important;
	bottom: 0;
	transform: rotate(180deg);
  }

  @media screen and (min-width: 640px) {
    .blurred-gradient {
      width: 356px;
      left: unset;
    }
  }
</style>
