import * as THREE from "three";
import Experience from "./Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import {
  CAMERA_START_POSITION_X,
  CAMERA_START_POSITION_Y,
  CAMERA_START_POSITION_Z,
  INTRO_ANIMATION_END_POSITION_Y,
} from "./config.js";

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.sizes = this.experience.sizes;
    this.isMobile = this.experience.mobileAndTabletCheck.check();

    this.setInstance();
  }

  setInstance() {
    this.calculateFOV();

    this.instance = new THREE.PerspectiveCamera(
      this.calculateFOV(),
      this.sizes.width / this.sizes.height,
      0.1,
      8610,
    );
    this.instance.position.set(
      CAMERA_START_POSITION_X,
      CAMERA_START_POSITION_Y,
      CAMERA_START_POSITION_Z,
    );
    this.instance.rotation.set(0, Math.PI + Math.PI / 4 - 0.1, 0);
    this.instance.lookAt(20, INTRO_ANIMATION_END_POSITION_Y, 0);

    this.cameraContainer = new THREE.Object3D();
    this.cameraContainer.add(this.instance);
    this.scene.add(this.cameraContainer);
  }

  calculateRotation(elementPosition) {
    const direction = new THREE.Vector3()
      .subVectors(elementPosition, this.cameraContainer.position)
      .normalize();
    let rotation =
      (Math.atan2(direction.x, direction.z) + Math.PI) % (2 * Math.PI);
    const fullRotations = Math.round(
      (this.cameraContainer.rotation.y - rotation) / (2 * Math.PI),
    );
    rotation = rotation + fullRotations * 2 * Math.PI;
    return rotation;
  }

  calculateFOV() {
    return this.isMobile ? 25 : 35;
  }

  calculateVideoWallFOV() {
    return this.isMobile ? 80 : 70;
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {}
}
