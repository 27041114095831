<script>
	import svgSprite from '../../svg/sprite/svgSprite.svg';

	export let icon;
	export let width = 16;
	export let height = 16;
</script>

<svg
	{width}
	{height}
	style={`--min-width: ${width}px; --min-height: ${height}px`}
	{...$$restProps}>
	<use href={`${svgSprite}#${icon}`} />
</svg>

<style lang="scss">
	svg {
		fill: var(--fill, #fff);
		min-width: var(--min-width);
		min-height: var(--min-height);
		transition: 0.2s ease all;
	}
</style>
