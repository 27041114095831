<script>
  import {fade, fly} from 'svelte/transition';
  import {translate} from '../../translations/index.js';
  import {players} from '../../store/players';
  import {state} from '../../store/state.js';
  import {selectedLanguage} from '../../store/selectedLanguage';
  import {shortlistedPlayers} from '../../store/shortlistedPlayers';
  import {REQUIRED_PLAYER_AMOUNT, STATE} from '../../Experience/config.js';
  import Tabs from '../components/Tabs.svelte';
  import Player from '../components/Player.svelte';
  import Svg from './Svg.svelte';
  import BlurredGradient from './BlurredGradient.svelte';
  import {saveStorageItem, STORAGE_KEYS} from '../services/storageService.js';

  let playerListActive = $state === STATE.SHORTLIST_CONFIRM;
  let activeTab = 'all';
  let prevState = false;
  let cursorAutoClass = 'cursor-auto';

  const activatePlayerList = (tab) => {
    if (playerListActive) {
      playerListActive = false;
      state.set(prevState ? prevState : STATE.FIRST_PERSON_ACTIVE);
      document.documentElement.classList.remove(cursorAutoClass);
    } else {
      playerListActive = true;
      prevState = $state;
      state.set(STATE.SHORTLIST_ACTIVE);
      document.documentElement.classList.add(cursorAutoClass);
      activeTab = tab || 'all';
    }
  }

  const performClick = () => {
    saveStorageItem(STORAGE_KEYS.HALL_OF_FAME, {
      language: $selectedLanguage,
      players: $shortlistedPlayers,
    });

    state.set(STATE.SHORTLIST_CONFIRM);
  }
</script>

<div transition:fade={{ duration: 300 }}>
	{#if $state !== STATE.SHORTLIST_ACTIVE}
		<div class="players" transition:fade={{ duration: 300 }}>
			<button class="btn list-btn" on:click={() => activatePlayerList('all')}>
				<Svg
						icon=icon-list
						width={24}
						height={24}
						class="hide-on-mobile"
						--fill="var(--color-white)"/>
				<span class="btn__label">
					<span>{translate('hof.players.players')}</span>
					<span>{translate('hof.players.players')}</span>
				</span>
			</button>
			<button class="btn selected-players" on:click={() => activatePlayerList('shortlist')}>
				<Svg
						icon='icon-heart-filled'
						width={20}
						height={20}
						--fill="var(--color-gold)"/>
				<span class="btn__label">
					<span>{$shortlistedPlayers.length} / {REQUIRED_PLAYER_AMOUNT}<span class="hide-on-mobile">&nbsp;{translate('hof.players.favorites')}</span></span>
					<span>{$shortlistedPlayers.length} / {REQUIRED_PLAYER_AMOUNT}<span class="hide-on-mobile">&nbsp;{translate('hof.players.favorites')}</span></span>
				</span>
			</button>
		</div>
	{/if}
</div>

{#if playerListActive}
	<div class="backdrop" transition:fade={{ duration: 600 }} on:click={() => activatePlayerList()} on:keyup={() => activatePlayerList()} role="button" tabindex="-1"></div>

	<div class="list" transition:fly={{ duration: 600, x: '100%', opacity: 1 }}>
		<div class="list__inner" transition:fade={{ duration: 600 }}>
			<BlurredGradient/>

			<Tabs bind:activeTab={activeTab}/>

			<div class="list__items">
				{#if activeTab === 'all'}
					{#each $players as player}
						<Player bind:player/>
					{/each}
				{:else}
					{#if $shortlistedPlayers.length === 0}
						<p class="empty">{translate('hof.players.empty')}</p>
					{:else}
						{#each $players.filter(obj => obj.shortlist) as player}
							<Player bind:player/>
						{/each}
					{/if}
				{/if}
			</div>

			<div class="buttons" transition:fade={{ duration: 300 }}>
				{#if $shortlistedPlayers.length === REQUIRED_PLAYER_AMOUNT}
					<button class="btn btn--primary buttons-submit" on:click={() => performClick()}>
						<span class="btn__label">
						<span>{translate('hof.players.submit')}</span>
						<span>{translate('hof.players.submit')}</span>
						</span>
					</button>
				{/if}

				<button class="btn btn--square btn--border buttons-close" on:click={() => activatePlayerList()}>
					<Svg
							icon=icon-close
							width={24}
							height={24}/>
				</button>
			</div>

			<BlurredGradient position="bottom"/>
		</div>
	</div>
{/if}

<style>
  .players {
    position: absolute;
    top: 0;
    right: 76px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    pointer-events: all;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
  }

  .selected-players,
  .list-btn {
    gap: 8px;
  }

  .list {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    pointer-events: all;
  }

  .list__inner {
    position: relative;
    height: 100%;
    width: 100%;
    margin-right: 20px;
    padding-right: 20px;
    overflow: auto;
  }

  .list__inner:after {
    content: '';
    position: fixed;
    top: 0;
    right: -16px;
    width: calc(100% + 100vw + 32px);
    height: 200px;
    background: linear-gradient(180deg, rgb(0 0 0 / 0.80) 0%, rgb(0 0 0 / 0.00) 100%);
    pointer-events: none;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--color-gold);
    border-radius: 0;
    border: none;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--color-white-20);
    border: none;
  }

  .list__items {
    min-height: 100%;
    margin: 16px 0 32px 0;
  }

  .empty {
    padding-top: 24px;
    text-align: center;
    font-weight: 300;
    color: var(--color-white);
  }

  .buttons {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding-bottom: 24px;
  }

  .buttons:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 200px;
    background: linear-gradient(0deg, rgb(0 0 0 / 0.80) 0%, rgb(0 0 0 / 0.00) 100%);
    pointer-events: none;
  }

  .buttons-submit,
  .buttons-close {
    position: relative;
    z-index: 1;
  }

  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: var(--color-black-80);
    backdrop-filter: blur(4px);
    pointer-events: all;
  }

  @supports not selector(::-webkit-scrollbar) {
    .list__inner {
      scrollbar-width: thin;
      scrollbar-color: var(--color-gold) var(--color-white-20);
    }
  }

  @media screen and (max-width: 639px) {
    .selected-players,
    .list-btn {
      height: 40px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @media screen and (min-width: 640px) {
    .players {
      right: 84px;
      gap: 16px;
      flex-direction: row;
      align-items: center;
    }

    .list {
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
      padding: 0 0 0 80px;
    }

    .list__inner {
      width: 360px;
    }

    .list__items {
      margin: 48px 0 32px 0;
    }

    .backdrop {
      background: linear-gradient(-90deg, var(--color-black) 0%, rgb(0 0 0 / 0.40) 80%, rgb(0 0 0 / 0.00) 100%);
    }
  }
</style>
