import Experience from "./Experience.js";

export default class Loader {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.controls = this.experience.controls;
    this.raycaster = this.experience.raycaster;
  }

  onLoadingProgress(itemsLoaded, itemsTotal) {
    const progressRatio = itemsLoaded / itemsTotal;
    // this.loadingBarElement.style.transform = `scaleX(${progressRatio})`;
  }
}
