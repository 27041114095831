import * as THREE from "three";
import Experience from "./Experience.js";

export default class Raycaster {
  constructor() {
    this.experience = new Experience();
    this.camera = this.experience.camera;
    this.scene = this.experience.scene;
    this.sizes = this.experience.sizes;
    this.raycaster = new THREE.Raycaster();
    this.cursor = new THREE.Vector2();
    this.allFrames = null;
    this.allFramesClick = null;

    this.videoWall = null;
  }

  pointerMoveHandler(eventX, eventY) {
    this.cursor.x = (eventX / this.sizes.width) * 2 - 1;
    this.cursor.y = -(eventY / this.sizes.height) * 2 + 1;

    this.raycaster.setFromCamera(this.cursor, this.camera.instance);

    this.getFrames();
    this.getVideoWall();
    this.setCurrentHovers();
  }

  touchHandler(event) {
    // Get the touch position. Check if there are intersections with an object
    const touch = event.center;
    const x = (touch.x / window.innerWidth) * 2 - 1;
    const y = -(touch.y / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera.instance);

    this.getFrames();
    this.getVideoWall();
    this.setCurrentHovers();
  }

  getVideoWall() {
    if (this.videoWall === null) {
      this.videoWall = this.scene.children
        .filter((obj) => obj.name === "Scene")[0]
        .children.filter((obj) => {
          return obj.name.includes("video_wall_");
        });
    }
  }

  getFrames() {
    if (this.allFrames === null) {
      this.allFrames = this.scene.children.filter((obj) => {
        return obj.name.includes("ScreenPlane");
      });
    }

    if (this.allFramesClick === null) {
      this.allFramesClick = this.scene.children.filter((obj) => {
        return obj.name.includes("frame_");
      });
    }
  }

  setCurrentHovers() {
    if (!this.allFrames) {
      return;
    }

    const intersects = this.raycaster.intersectObjects(this.allFrames);
    this.raycaster.currentHover = intersects.length > 0 ? intersects[0] : null;

    const panelIntersects = this.raycaster.intersectObjects(
      this.allFramesClick,
    );
    this.raycaster.currentHoverPanel =
      panelIntersects.length > 0 ? panelIntersects[0] : null;

    const videoWallIntersects = this.raycaster.intersectObjects(this.videoWall);
    this.raycaster.currentHoverVideoWall =
      videoWallIntersects.length > 0 ? videoWallIntersects[0] : null;
  }

  update() {}
}
