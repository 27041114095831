<script>
  import {fade} from 'svelte/transition';
  import {ONBOARDING_STATES} from '../../Experience/config.js';
  import {state} from '../../store/state.js';

  export let type = 'default';
</script>

<div in:fade={{ delay: 450, duration: 400 }}>
	{#if ONBOARDING_STATES.includes($state)}
		<img class="logo logo--{type}" src="/images/icons/logo-hall-of-fame-tagline.svg?v=1" alt="Logo Hall of Fame" out:fade={{duration: 400}}>
	{:else}
		<img class="logo logo--{type}" src="/images/icons/logo-hall-of-fame.svg?v=1" alt="Logo Hall of Fame" >
	{/if}
</div>

<style>
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 36px;
  }

  @media screen and (min-width: 640px) {
    .logo {
      height: 56px;
    }
  }
</style>
