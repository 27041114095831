import * as THREE from "three";
import { get } from "svelte/store";
import Experience from "../Experience.js";

import wallVideoVertexShader from "../Shaders/wall-video/vertex.glsl";
import wallVideoFragmentShader from "../Shaders/wall-video/fragment.glsl";
import { selectedLanguage } from "../../store/selectedLanguage.js";

export default class Video {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.videoService = this.experience.videoService;

    setTimeout(() => {
      this.scene.scene = this.scene.children.filter(
        (obj) => obj.name === "Scene",
      )[0];
      this.wall1 = this.scene.scene.children.filter(
        (obj) => obj.name === "video_wall_1",
      )[0];
      this.wall2 = this.scene.scene.children.filter(
        (obj) => obj.name === "video_wall_2",
      )[0];
      this.videoElement = null;

      this.videoService.setVideo(
        "background-video",
        `videos/background.mp4?v=1`,
      );
      this.setVideoScreen();
    });
  }

  setWallVideo() {
    this.videoService.setVideo(
      "background-video",
      `videos/background-${get(selectedLanguage)}.mp4?v=1`,
    );
    this.setVideoScreen();
  }

  setVideoScreen() {
    this.videoElement = document.getElementById("background-video");

    this.videoTexture = new THREE.VideoTexture(this.videoElement);
    this.videoTexture.colorSpace = THREE.SRGBColorSpace;
    this.videoTexture.flipY = false;

    this.videoCombinedMaterial = new THREE.ShaderMaterial({
      transparent: 1,
      uniforms: {
        videoTexture: { value: this.videoTexture },
        alphaMap: { value: this.resources.items.wallVideoOverlay },
      },
      vertexShader: wallVideoVertexShader,
      fragmentShader: wallVideoFragmentShader,
    });

    this.wall1.material = this.videoCombinedMaterial;
    this.wall2.material = this.videoCombinedMaterial;
  }
}
