uniform sampler2D videoTexture;
uniform sampler2D alphaMap;
uniform vec2 resolution;
uniform float opacity; // New uniform

varying vec2 vUv;

void main() {
    vec4 videoColor = texture2D(videoTexture, vUv);
    vec4 alphaMapColor = texture2D(alphaMap, vUv);
    float alpha = 1.0 - alphaMapColor.a;

    gl_FragColor = vec4(videoColor.rgb, videoColor.a * alpha * opacity);
}
