<script>
  import {onMount} from 'svelte';
  import {state} from '../../store/state.js';
  import {STATE} from '../../Experience/config.js';

  export let spinnerComplete;

  let loadingSpinnerElement;

  onMount(() => {
    loadingSpinnerElement.addEventListener('animationiteration', () => {
      if ($state === STATE.LOADING_COMPLETE) {
        spinnerComplete = true;

        setTimeout(() => {
		  state.set(STATE.INTRO_ZOOM)
		}, 500);
	  }
    });
  });
</script>

<img class="loading__spinner" alt="Loading icon" class:complete={spinnerComplete} bind:this={loadingSpinnerElement} src="/images/icons/logo-element.svg">

<style>
  .loading__spinner {
    width: 4vh;
    height: 4vh;
    margin-top: 0.8vh;
    animation: spinner 1s ease-in-out infinite;
  }

  .loading__spinner.complete {
    animation-play-state: paused;
  }

  @keyframes spinner {
	0% {
	  transform: rotate(0);
	}

    100% {
      transform: rotate(360deg);
    }
  }
</style>
