<script>
  import {get} from 'svelte/store';
  import {fade} from 'svelte/transition';
  import {updateShortlist} from '../services/shortlistService.js';
  import {activePlayer} from '../../store/activePlayer.js';
  import {shortlistedPlayers} from '../../store/shortlistedPlayers.js';
  import {translate} from '../../translations/index.js';
  import {REQUIRED_PLAYER_AMOUNT} from '../../Experience/config.js';
  import Svg from './Svg.svelte';
  import {submissionCompleted} from '../../store/submissionCompleted.js';

  let isAdd = true;
  let shortlistFull = false;

  const close = () => {
    window.Frame.exitHandler()
  }

  const favorite = () => {
    if (get(shortlistedPlayers).length === REQUIRED_PLAYER_AMOUNT && !isAdd) {
		shortlistFull = true;

		setTimeout(() => {
			shortlistFull = false;
		}, 3000)
	} else {
      updateShortlist(get(activePlayer))
	}

    isAdd = get(shortlistedPlayers).includes(get(activePlayer))
  }

  const prev = () => {
    window.Frame.prevNextHandler('prev')
  }

  const next = () => {
    window.Frame.prevNextHandler('next')
  }

  function updateActivePlayer(player) {
    isAdd = get(shortlistedPlayers).includes(player)
  }

  $: updateActivePlayer($activePlayer);
</script>

<div class="nav" transition:fade={{ duration: 300 }}>
	<button class="btn btn--square" on:click={() => prev()}>
		<Svg
				icon='icon-chevron-left'
				width={24}
				height={24}/>
	</button>
	<button class="btn btn--square" on:click={() => next()}>
		<Svg
				icon='icon-chevron-right'
				width={24}
				height={24}/>
	</button>
</div>

<div class="buttons" transition:fade={{ duration: 300 }}>
	{#if !$submissionCompleted}
		{#if shortlistFull}
			<div class="alert" transition:fade={{ duration: 400 }}>{translate('hof.players.max.1')} {REQUIRED_PLAYER_AMOUNT} {translate('hof.players.max.2')}</div>
		{/if}

		<button class="btn btn--primary btn--add" class:active={isAdd} on:click={() => favorite()}>
			<Svg
					icon='icon-heart-filled'
					width={22}
					height={22}/>
			<span class="btn__label">
				<span>{isAdd ? translate('hof.players.activeplayer.remove') : translate('hof.players.activeplayer.add')}</span>
				<span>{isAdd ? translate('hof.players.activeplayer.remove') : translate('hof.players.activeplayer.add')}</span>
			</span>
		</button>
	{/if}

	<button class="btn btn--close" on:click={() => close()}>
		<Svg
				icon='icon-close'
				width={28}
				height={28}
				--fill="var(--color-white)"/>
	</button>
</div>

<style>
  .nav {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .alert {
    position: absolute;
	left: 50%;
    bottom: calc(100% + 12px);
	transform: translateX(-50%);
	background: var(--color-white-10);
	border: 1px solid var(--color-white);
	color: var(--color-white);
	z-index: 1;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 0;
  }

  .nav .btn {
    pointer-events: all;
  }

  .buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 12px;
    pointer-events: all;
  }

  .btn--close {
    justify-content: center;
    width: 48px;
    padding: 0;
    flex-shrink: 0;
  }

  :global(.btn--close:hover svg) {
    height: 20px;
  }

  .btn--add.active {
    background: transparent;
    color: var(--color-white);
  }

  .btn--add.active .btn__label span:first-of-type {
	  color: var(--color-white);
  }

  :global(.btn--add svg) {
    fill: transparent;
    stroke: var(--color-black);
    stroke-width: 2px;
	  transition: all .6s ease;
  }

  :global(.btn--add.active svg) {
    fill: var(--color-gold);
    stroke: var(--color-gold);
  }

  :global(.btn--add:hover svg) {
    fill: var(--color-white);
    stroke: var(--color-white);
  }

  @media screen and (min-width: 640px) {
    .btn--add {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 640px) {
    .btn--add {
      flex-grow: 1;
      padding: 12px;
    }
  }
</style>
