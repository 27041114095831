import Experience from "./Experience.js";
import { state } from "../store/state.js";
import { pointer } from "../store/pointer.js";
import { STATE } from "./config.js";
import { get } from "svelte/store";

export default class Shirts {
  constructor() {
    this.experience = new Experience();
    this.raycaster = this.experience.raycaster;
    this.location = null;
  }

  hover(event) {
    this.panelElement = this.raycaster.raycaster.currentHoverPanel;
    this.videoElement = this.raycaster.raycaster.currentHoverVideoWall;
    this.hoveringVideo =
      this.videoElement &&
      (this.videoElement.object.name === "video_wall_1" ||
        this.videoElement.object.name === "video_wall_2");
    this.hoveringFrame =
      this.panelElement && this.panelElement.object.name.includes("frame_back");

    if (
      get(state) === STATE.FIRST_PERSON_ACTIVE ||
      get(state) === STATE.FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO
    ) {
      state.set(
        this.hoveringVideo
          ? STATE.FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO
          : STATE.FIRST_PERSON_ACTIVE,
      );
    }

    if (
      get(state) === STATE.FIRST_PERSON_ACTIVE ||
      get(state) === STATE.FIRST_PERSON_ACTIVE_AND_HOVERING
    ) {
      state.set(
        this.hoveringFrame
          ? STATE.FIRST_PERSON_ACTIVE_AND_HOVERING
          : STATE.FIRST_PERSON_ACTIVE,
      );
    }

    document.body.dataset.pointerHover = !!(
      get(state) === STATE.FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO ||
      get(state) === STATE.FIRST_PERSON_ACTIVE_AND_HOVERING
    );

    pointer.update((currentValue) => {
      return { ...currentValue, location: [event.clientX, event.clientY] };
    });
  }
}
