import { state } from "../../store/state.js";
import { STATE } from "../../Experience/config.js";
import { translate } from "../../translations/index.js";
import Events from "../../Experience/Events.js";
import MobileAndTabletCheck from "../../Experience/Utils/MobileAndTabletCheck.js";

export const initControls = () => {
  const event = new Events();
  const isMobile = new MobileAndTabletCheck().check();
  const lookAroundControls = window.experience.controls.lookAroundControls;

  if (isMobile) {
    event.initPanControls();
    return lookAroundControls.requestPermission();
  } else {
    event.initScrollControls();
    return true;
  }
};
