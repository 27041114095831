import { getPlayersByShortlist } from "./shortlistService.js";
import { get } from "svelte/store";
import { user } from "../../store/user.js";

export const saveSubmission = async () => {
  const userData = get(user);

  const formData = new FormData();
  formData.append("id", userData.sub);
  formData.append("email", userData.email);

  getPlayersByShortlist().forEach((player) => {
    formData.append(
      "players[]",
      JSON.stringify({
        firstName: player.firstname,
        lastName: player.lastname,
      }),
    );
  });

  const result = await fetch(`/api-simple/?module=hof&action=submission`, {
    method: "POST",
    body: formData,
    redirect: "follow",
  });

  const response = await result.json();

  return response.status === "success";
};
