<script>
  import {fade} from 'svelte/transition';
  import {translate} from '../../translations/index.js';
  import Svg from './Svg.svelte';

  let soundOn = false;

  const toggleSound = () => {
    soundOn = !soundOn;

    const element = document.querySelector('#background-video');

    if (!element) {
      return;
    }

    element.muted = !element.muted;
  }
</script>

<button class="sound" on:click={() => toggleSound()} transition:fade={{ duration: 300 }}>
	<Svg
			icon={soundOn ? 'icon-audio-on' : 'icon-audio-off'}
			width={32}
			height={32}
			--fill="var(--color-white)"/>

	<span>{soundOn ? translate('hof.sound.on') : translate('hof.sound.off')}</span>
</button>

<style>
  .sound {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    pointer-events: all;
    font-weight: 500;
    font-size: 12px;
  }

  @media screen and (min-width: 640px) {
    .sound {
      font-size: 14px;
    }
  }
</style>
