<script>
  import {shortlistedPlayers} from '../../store/shortlistedPlayers.js';
  import {translate} from '../../translations/index.js';
  import {REQUIRED_PLAYER_AMOUNT} from '../../Experience/config.js';
  import Svg from './Svg.svelte';

  export let activeTab;

  const switchTabs = (index) => {
    activeTab = index
  }
</script>

<div class="tabs">
	<div class="tabs__items">
		<button class="tabs__item" class:active={activeTab === 'all'} on:click={() => switchTabs('all')}>
			{translate('hof.players.all')}
		</button>
		<button class="tabs__item" class:active={activeTab === 'shortlist'} on:click={() => switchTabs('shortlist')}>
			<Svg
					icon='icon-heart-filled'
					width={20}
					height={20}/>
			<span>{$shortlistedPlayers.length} / {REQUIRED_PLAYER_AMOUNT} {translate('hof.players.favorites')}</span>
		</button>
		<div class="glider"></div>
	</div>
</div>

<style>
  .tabs {
    position: sticky;
    top: 16px;
    z-index: 1;
    width: calc(100% - 16px);
    margin-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 1000px;
    background: var(--color-white-10);
  }

  .tabs__items {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
    padding: 8px;
    margin: 0;
    border-radius: 40px;
    right: auto;
    width: 100%;
  }

  .tabs__item {
    --fill: var(--color-gold);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-grow: 1;
    height: 48px;
    width: 100%;
    border-radius: 40px;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .glider {
    position: absolute;
    top: 8px;
    left: 0;
    z-index: -1;
    width: calc(50% - 4px);
    height: calc(100% - 16px);
    background-color: var(--color-gold);
    border-radius: 40px;
    transition: all 0.3s ease;
  }

  .tabs__item:nth-child(2).active ~ .glider {
    top: auto;
    left: calc(50% + 4px);
  }

  .tabs__item:hover {
    background-color: var(--color-white-10);
    backdrop-filter: 20px;
  }

  .tabs__item.active {
    --fill: var(--color-black);

    color: var(--color-black);
  }

  @media screen and (min-width: 640px) {
    .tabs {
      width: 356px;
      top: 48px;
      margin-top: 32px;
    }

    .tabs__item {
      width: calc(50%);
    }
  }
</style>
