<script>
  import MobileAndTabletCheck from '../../Experience/Utils/MobileAndTabletCheck.js';
</script>

{#if !new MobileAndTabletCheck().check()}
	<div class="left" on:mouseenter={() => window.Frame.hoverToRotateHandler('left')} on:mouseleave={() => window.Frame.stopHoverToRotateHandler()} role="none"></div>
	<div class="right" on:mouseenter={() => window.Frame.hoverToRotateHandler('right')} on:mouseleave={() => window.Frame.stopHoverToRotateHandler()} role="none"></div>
{/if}

<style>
  .left,
  .right {
    position: fixed;
    right: 0;
    top: 0;
	z-index: -1;
    width: 100px;
    height: 100%;
    pointer-events: all;
  }

  .right {
    right: 0;
  }

  .left {
    left: 0;
  }
</style>
