<div class="overlay"></div>

<style>
	.overlay {
      position: fixed;
	  top: 0;
	  left: 0;
	  height: 100%;
	  width: 100%;

      /* background-color: rgba(22, 0, 43, 0.1); */
	}

    .overlay:before,
    .overlay:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .overlay:after {
      background-image: url(/images/misc/grain.png);
	  animation: animate 0.1s linear infinite;
	  opacity: 0.3;
    }

    .overlay:before {
      background: radial-gradient(circle, rgb(0 0 0 / 0) 50%, rgb(0 0 0 / 0.5) 100%);
    }

	@keyframes animate {
      0% {
		background-position: 0 0;
	  }

      10% {
		background-position: -5% -5%;
	  }

      20% {
		background-position: -80% 5%;
	  }

      30% {
		background-position: 5% -50%;
	  }

      40% {
		background-position: -6% 15%;
	  }

      50% {
		background-position: -10% 8%;
	  }

      60% {
		background-position: 85% 0;
	  }

      70% {
		background-position: 0 40%;
	  }

      80% {
		background-position: -55% 0;
	  }

      90% {
		background-position: 10% 99%;
	  }

      100% {
		background-position: 77% 0;
	  }
    }
</style>
