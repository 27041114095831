export const STORAGE_KEYS = {
  HALL_OF_FAME: "hall_of_fame",
  UNIDY_TOKEN: "unidy_token",
};

export const getStorateItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const hasStorageItem = (key) => {
  return !!localStorage.getItem(key);
};

export const removeStorageItem = (key) => {
  localStorage.removeItem(key);
};

export const saveStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
