import { writable } from "svelte/store";

export const players = writable([
  {
    id: 0,
    active: false,
    shortlist: false,
    firstname: "Barry",
    lastname: "Boubacar Copa",
    club: "KSC Lokeren-Temse",
    games: "242",
    logoUrl: "images/clubs/KSC_Lokeren-Temse.png",
    imageUrl: "players/images/Barry_Boubacar_Copa.jpg",
    video: "players/videos/Barry_Boubacar_Copa.mp4?v=1",
  },
  {
    id: 1,
    active: false,
    shortlist: false,
    firstname: "Bert",
    lastname: "Dhont",
    club: "KMSK Deinze",
    games: "14",
    logoUrl: "images/clubs/KMSK_Deinze.png",
    imageUrl: "players/images/Bert_Dhont.jpg",
    video: "players/videos/Bert_Dhont.mp4?v=1",
  },
  {
    id: 2,
    active: false,
    shortlist: false,
    firstname: "Bjorn",
    lastname: "Ruytinx",
    club: "OH Leuven",
    games: "238",
    logoUrl: "images/clubs/OH_Leuven.png",
    imageUrl: "players/images/Bjorn_Ruytinx.jpg",
    video: "players/videos/Bjorn_Ruytinx.mp4?v=1",
  },
  {
    id: 3,
    active: false,
    shortlist: false,
    firstname: "Boudewijn",
    lastname: "Braem",
    club: "KV Kortrijk",
    games: "112",
    logoUrl: "images/clubs/KV_Kortrijk.png",
    imageUrl: "players/images/Boudewijn_Braem.jpg",
    video: "players/videos/Boudewijn_Braem.mp4?v=1",
  },
  {
    id: 4,
    active: false,
    shortlist: false,
    firstname: "Branko",
    lastname: "Strupar",
    club: "KRC Genk",
    games: "110",
    logoUrl: "images/clubs/KRC_Genk.png",
    imageUrl: "players/images/Branko_Strupar.jpg",
    video: "players/videos/Branko_Strupar.mp4?v=1",
  },
  {
    id: 5,
    active: false,
    shortlist: false,
    firstname: "Bryan",
    lastname: "Ruiz",
    club: "KAA Gent",
    games: "79",
    logoUrl: "images/clubs/KAA_Gent.png",
    imageUrl: "players/images/Bryan_Ruiz.jpg",
    video: "players/videos/Bryan_Ruiz.mp4?v=1",
  },
  {
    id: 6,
    active: false,
    shortlist: false,
    firstname: "Danny",
    lastname: "Boffin",
    club: "STVV",
    games: "96",
    logoUrl: "images/clubs/STVV.png",
    imageUrl: "players/images/Danny_Boffin.jpg",
    video: "players/videos/Danny_Boffin.mp4?v=1",
  },
  {
    id: 7,
    active: false,
    shortlist: false,
    firstname: "Dante",
    lastname: "Brogno",
    club: "Sporting Charleroi",
    games: "389",
    logoUrl: "images/clubs/Sporting_Charleroi.png",
    imageUrl: "players/images/Dante_Brogno.jpg",
    video: "players/videos/Dante_Brogno.mp4?v=1",
  },
  {
    id: 8,
    active: false,
    shortlist: false,
    firstname: "Dieumerci",
    lastname: "Mbokani",
    club: "Wildcards",
    games: "264",
    logoUrl: "images/clubs/Wildcards.png?v=1",
    imageUrl: "players/images/Dieumerci_Mbokani.jpg",
    video: "players/videos/Dieumerci_Mbokani.mp4?v=1",
  },
  {
    id: 9,
    active: false,
    shortlist: false,
    firstname: "Edhem",
    lastname: "Sljivo",
    club: "RFC Liège",
    games: "193",
    logoUrl: "images/clubs/RFC_Liege.png",
    imageUrl: "players/images/Edhem_Sljivo.jpg",
    video: "players/videos/Edhem_Sljivo.mp4?v=1",
  },
  {
    id: 10,
    active: false,
    shortlist: false,
    firstname: "Edmilson",
    lastname: "Paulo Da Silva",
    club: "RFC Seraing",
    games: "88",
    logoUrl: "images/clubs/RFC_Seraing.png",
    imageUrl: "players/images/Edmilson_Paulo_Da_Silva.jpg",
    video: "players/videos/Edmilson_Paulo_Da_Silva.mp4?v=1",
  },
  {
    id: 11,
    active: false,
    shortlist: false,
    firstname: "Enzo",
    lastname: "Scifo",
    club: "RSC Anderlecht",
    games: "197",
    logoUrl: "images/clubs/RSC_Anderlecht.png",
    imageUrl: "players/images/Enzo_Scifo.jpg",
    video: "players/videos/Enzo_Scifo.mp4?v=1",
  },
  {
    id: 12,
    active: false,
    shortlist: false,
    firstname: "Eric",
    lastname: "Gerets",
    club: "Standard de Liège",
    games: "281",
    logoUrl: "images/clubs/Standard_de_Liege.png",
    imageUrl: "players/images/Eric_Gerets.jpg",
    video: "players/videos/Eric_Gerets.mp4?v=1",
  },
  {
    id: 13,
    active: false,
    shortlist: false,
    firstname: "Eric",
    lastname: "Van Meir",
    club: "Lierse",
    games: "183",
    logoUrl: "images/clubs/Lierse.png",
    imageUrl: "players/images/Eric_Van_Meir.jpg",
    video: "players/videos/Eric_Van_Meir.mp4?v=1",
  },
  {
    id: 14,
    active: false,
    shortlist: false,
    firstname: "Erwin",
    lastname: "Vandenbergh",
    club: "Wildcards",
    games: "427",
    logoUrl: "images/clubs/Wildcards.png?v=1",
    imageUrl: "players/images/Erwin_Vandenbergh.jpg",
    video: "players/videos/Erwin_Vandenbergh.mp4?v=1",
  },
  {
    id: 15,
    active: false,
    shortlist: false,
    firstname: "Frank",
    lastname: "Vercauteren",
    club: "RSC Anderlecht",
    games: "367",
    logoUrl: "images/clubs/RSC_Anderlecht.png",
    imageUrl: "players/images/Frank_Vercauteren.jpg",
    video: "players/videos/Frank_Vercauteren.mp4?v=1",
  },
  {
    id: 16,
    active: false,
    shortlist: false,
    firstname: "Franky",
    lastname: "Van der Elst",
    club: "Club Brugge",
    games: "466",
    logoUrl: "images/clubs/Club_Brugge.png",
    imageUrl: "players/images/Franky_Van_der_Elst.jpg",
    video: "players/videos/Franky_Van_der_Elst.mp4?v=1",
  },
  {
    id: 17,
    active: false,
    shortlist: false,
    firstname: "Frédéric",
    lastname: "Tilmant",
    club: "RAAL La Louvière",
    games: "96",
    logoUrl: "images/clubs/RAAL_La_Louviere.png",
    imageUrl: "players/images/Frederic_Tilmant.jpg",
    video: "players/videos/Frederic_Tilmant.mp4?v=1",
  },
  {
    id: 18,
    active: false,
    shortlist: false,
    firstname: "Gert",
    lastname: "Cannaerts",
    club: "Lommel SK",
    games: "205",
    logoUrl: "images/clubs/Lommel_SK.png",
    imageUrl: "players/images/Gert_Cannaerts.jpg",
    video: "players/videos/Gert_Cannaerts.mp4?v=1",
  },
  {
    id: 19,
    active: false,
    shortlist: false,
    firstname: "Gert",
    lastname: "Verheyen",
    club: "Club Brugge",
    games: "415",
    logoUrl: "images/clubs/Club_Brugge.png",
    imageUrl: "players/images/Gert_Verheyen.jpg",
    video: "players/videos/Gert_Verheyen.mp4?v=1",
  },
  {
    id: 20,
    active: false,
    shortlist: false,
    firstname: "Guy",
    lastname: "Vandersmissen",
    club: "Standard de Liège",
    games: "370",
    logoUrl: "images/clubs/Standard_de_Liege.png",
    imageUrl: "players/images/Guy_Vandersmissen.jpg",
    video: "players/videos/Guy_Vandersmissen.mp4?v=1",
  },
  {
    id: 21,
    active: false,
    shortlist: false,
    firstname: "Hans-Peter",
    lastname: "Lehnhoff",
    club: "R. Antwerp FC",
    games: "229",
    logoUrl: "images/clubs/R_Antwerp_FC.png",
    imageUrl: "players/images/Hans-Peter_Lehnhoff.jpg",
    video: "players/videos/Hans-Peter_Lehnhoff.mp4?v=1",
  },
  {
    id: 22,
    active: false,
    shortlist: false,
    firstname: "Heinz",
    lastname: "Schönberger",
    club: "SK Beveren",
    games: "238",
    logoUrl: "images/clubs/SK_Beveren.png",
    imageUrl: "players/images/Heinz_Schonberger.jpg",
    video: "players/videos/Heinz_Schonberger.mp4?v=1",
  },
  {
    id: 23,
    active: false,
    shortlist: false,
    firstname: "Jan",
    lastname: "Ceulemans",
    club: "Club Brugge",
    games: "407",
    logoUrl: "images/clubs/Club_Brugge.png",
    imageUrl: "players/images/Jan_Ceulemans.jpg",
    video: "players/videos/Jan_Ceulemans.mp4?v=1",
  },
  {
    id: 24,
    active: false,
    shortlist: false,
    firstname: "Jean-Marie",
    lastname: "Pfaff",
    club: "SK Beveren",
    games: "249",
    logoUrl: "images/clubs/SK_Beveren.png",
    imageUrl: "players/images/Jean-Marie_Pfaff.jpg",
    video: "players/videos/Jean-Marie_Pfaff.mp4?v=1",
  },
  {
    id: 25,
    active: false,
    shortlist: false,
    firstname: "Jef",
    lastname: "Delen",
    club: "KVC Westerlo",
    games: "357",
    logoUrl: "images/clubs/KVC_Westerlo.png",
    imageUrl: "players/images/Jef_Delen.jpg",
    video: "players/videos/Jef_Delen.mp4?v=1",
  },
  {
    id: 26,
    active: false,
    shortlist: false,
    firstname: "Johan",
    lastname: "Boskamp",
    club: "RWDM",
    games: "238",
    logoUrl: "images/clubs/RWDM.png",
    imageUrl: "players/images/Johan_Boskamp.jpg",
    video: "players/videos/Johan_Boskamp.mp4?v=1",
  },
  {
    id: 27,
    active: false,
    shortlist: false,
    firstname: "Juan",
    lastname: "Lozano",
    club: "K. Beerschot VA",
    games: "172",
    logoUrl: "images/clubs/K_Beerschot_VA.png",
    imageUrl: "players/images/Juan_Lozano.jpg",
    video: "players/videos/Juan_Lozano.mp4?v=1",
  },
  {
    id: 28,
    active: false,
    shortlist: false,
    firstname: "Julien",
    lastname: "Verriest",
    club: "Cercle Brugge",
    games: "185",
    logoUrl: "images/clubs/Cercle_Brugge.png",
    imageUrl: "players/images/Julien_Verriest.jpg",
    video: "players/videos/Julien_Verriest.mp4?v=1",
  },
  {
    id: 29,
    active: false,
    shortlist: false,
    firstname: "Lambic",
    lastname: "Wawa",
    club: "RWDM",
    games: "122",
    logoUrl: "images/clubs/RWDM.png",
    imageUrl: "players/images/Lambic_Wawa.jpg",
    video: "players/videos/Lambic_Wawa.mp4?v=1",
  },
  {
    id: 30,
    active: false,
    shortlist: false,
    firstname: "Laurent",
    lastname: "Depoitre",
    club: "KAA Gent",
    games: "182",
    logoUrl: "images/clubs/KAA_Gent.png",
    imageUrl: "players/images/Laurent_Depoitre.jpg",
    video: "players/videos/Laurent_Depoitre.mp4?v=1",
  },
  {
    id: 31,
    active: false,
    shortlist: false,
    firstname: "Lei",
    lastname: "Clijsters",
    club: "KV Mechelen",
    games: "176",
    logoUrl: "images/clubs/KV_Mechelen.png",
    imageUrl: "players/images/Lei_Clijsters.jpg",
    video: "players/videos/Lei_Clijsters.mp4?v=2",
  },
  {
    id: 32,
    active: false,
    shortlist: false,
    firstname: "Lorenzo",
    lastname: "Lai",
    club: "Royal Francs Borains",
    games: "71",
    logoUrl: "images/clubs/Royal_Francs_Borains.png",
    imageUrl: "players/images/Lorenzo_Lai.jpg",
    video: "players/videos/Lorenzo_Lai.mp4?v=1",
  },
  {
    id: 33,
    active: false,
    shortlist: false,
    firstname: "Luc",
    lastname: "Nilis",
    club: "RSC Anderlecht",
    games: "223",
    logoUrl: "images/clubs/RSC_Anderlecht.png",
    imageUrl: "players/images/Luc_Nilis.jpg",
    video: "players/videos/Luc_Nilis.mp4?v=1",
  },
  {
    id: 34,
    active: false,
    shortlist: false,
    firstname: "Ludo",
    lastname: "Coeck",
    club: "Wildcards",
    games: "249",
    logoUrl: "images/clubs/Wildcards.png?v=1",
    imageUrl: "players/images/Ludo_Coeck.jpg",
    video: "players/videos/Ludo_Coeck.mp4?v=1",
  },
  {
    id: 35,
    active: false,
    shortlist: false,
    firstname: "Ludwin",
    lastname: "van Nieuwenhuyze",
    club: "Zulte Waregem",
    games: "175",
    logoUrl: "images/clubs/Zulte_Waregem.png",
    imageUrl: "players/images/Ludwin_van_Nieuwenhuyze.jpg",
    video: "players/videos/Ludwin_van_Nieuwenhuyze.mp4?v=1",
  },
  {
    id: 36,
    active: false,
    shortlist: false,
    firstname: "Luis",
    lastname: "García Fernández",
    club: "KAS Eupen",
    games: "182",
    logoUrl: "images/clubs/KAS_Eupen.png",
    imageUrl: "players/images/Luis_Garcia.jpg",
    video: "players/videos/Luis_Garcia.mp4?v=1",
  },
  {
    id: 37,
    active: false,
    shortlist: false,
    firstname: "Sanharib",
    lastname: "Malki Sabah",
    club: "R. Union SG",
    games: "18",
    logoUrl: "images/clubs/R_Union_SG.png",
    imageUrl: "players/images/Malki_Sanharib_Sabah.jpg",
    video: "players/videos/Malki_Sanharib_Sabah.mp4?v=1",
  },
  {
    id: 38,
    active: false,
    shortlist: false,
    firstname: "Marc",
    lastname: "Degryse",
    club: "Wildcards",
    games: "476",
    logoUrl: "images/clubs/Wildcards.png?v=1",
    imageUrl: "players/images/Marc_Degryse.jpg",
    video: "players/videos/Marc_Degryse.mp4?v=1",
  },
  {
    id: 39,
    active: false,
    shortlist: false,
    firstname: "Mbark",
    lastname: "Boussoufa",
    club: "Wildcards",
    games: "216",
    logoUrl: "images/clubs/Wildcards.png?v=1",
    imageUrl: "players/images/Mbark_Boussoufa.jpg",
    video: "players/videos/Mbark_Boussoufa.mp4?v=1",
  },
  {
    id: 40,
    active: false,
    shortlist: false,
    firstname: "Mehdi",
    lastname: "Carcela",
    club: "Standard de Liège",
    games: "256",
    logoUrl: "images/clubs/Standard_de_Liege.png",
    imageUrl: "players/images/Mehdi_Carcela.jpg",
    video: "players/videos/Mehdi_Carcela.mp4?v=1",
  },
  {
    id: 41,
    active: false,
    shortlist: false,
    firstname: "Michel",
    lastname: "Preud'homme",
    club: "KV Mechelen",
    games: "263",
    logoUrl: "images/clubs/KV_Mechelen.png",
    imageUrl: "players/images/Michel_Preud_homme.jpg",
    video: "players/videos/Michel_Preud_homme.mp4?v=1",
  },
  {
    id: 42,
    active: false,
    shortlist: false,
    firstname: "Nico",
    lastname: "Van Kerckhoven",
    club: "Lierse",
    games: "164",
    logoUrl: "images/clubs/Lierse.png",
    imageUrl: "players/images/Nico_Van_Kerckhoven.jpg",
    video: "players/videos/Nico_Van_Kerckhoven.mp4?v=1",
  },
  {
    id: 43,
    active: false,
    shortlist: false,
    firstname: "Pär",
    lastname: "Zetterberg",
    club: "RSC Anderlecht",
    games: "285",
    logoUrl: "images/clubs/RSC_Anderlecht.png",
    imageUrl: "players/images/Par_Zetterberg.jpg",
    video: "players/videos/Par_Zetterberg.mp4?v=1",
  },
  {
    id: 44,
    active: false,
    shortlist: false,
    firstname: "Philippe",
    lastname: "Albert",
    club: "Wildcards",
    games: "247",
    logoUrl: "images/clubs/Wildcards.png?v=1",
    imageUrl: "players/images/Philippe_Albert.jpg",
    video: "players/videos/Philippe_Albert.mp4?v=1",
  },
  {
    id: 45,
    active: false,
    shortlist: false,
    firstname: "Raoul",
    lastname: "Lambert",
    club: "Club Brugge",
    games: "115",
    logoUrl: "images/clubs/Club_Brugge.png",
    imageUrl: "players/images/Raoul_Lambert.jpg",
    video: "players/videos/Raoul_Lambert.mp4?v=1",
  },
  {
    id: 46,
    active: false,
    shortlist: false,
    firstname: "Rob",
    lastname: "Rensenbrink",
    club: "RSC Anderlecht",
    games: "178",
    logoUrl: "images/clubs/RSC_Anderlecht.png",
    imageUrl: "players/images/Rob_Rensenbrink.jpg",
    video: "players/videos/Rob_Rensenbrink.mp4?v=1",
  },
  {
    id: 47,
    active: false,
    shortlist: false,
    firstname: "Rudi",
    lastname: "Smidts",
    club: "R. Antwerp FC",
    games: "408",
    logoUrl: "images/clubs/R_Antwerp_FC.png",
    imageUrl: "players/images/Rudi_Smidts.jpg",
    video: "players/videos/Rudi_Smidts.mp4?v=1",
  },
  {
    id: 48,
    active: false,
    shortlist: false,
    firstname: "Ruud",
    lastname: "Vormer",
    club: "Club Brugge",
    games: "268",
    logoUrl: "images/clubs/Club_Brugge.png",
    imageUrl: "players/images/Ruud_Vormer.jpg",
    video: "players/videos/Ruud_Vormer.mp4?v=1",
  },
  {
    id: 49,
    active: false,
    shortlist: false,
    firstname: "Simon",
    lastname: "Tahamata",
    club: "Wildcards",
    games: "409",
    logoUrl: "images/clubs/Wildcards.png?v=1",
    imageUrl: "players/images/Simon_Tahamata.jpg",
    video: "players/videos/Simon_Tahamata.mp4?v=1",
  },
  {
    id: 50,
    active: false,
    shortlist: false,
    firstname: "Steven",
    lastname: "De Petter",
    club: "FCV Dender EH",
    games: "86",
    logoUrl: "images/clubs/FCV_Dender_EH.png",
    imageUrl: "players/images/Steven_De_Petter.jpg",
    video: "players/videos/Steven_De_Petter.mp4?v=1",
  },
  {
    id: 51,
    active: false,
    shortlist: false,
    firstname: "Thomas",
    lastname: "Buffel",
    club: "KRC Genk",
    games: "314",
    logoUrl: "images/clubs/KRC_Genk.png",
    imageUrl: "players/images/Thomas_Buffel.jpg",
    video: "players/videos/Thomas_Buffel.mp4?v=1",
  },
  {
    id: 52,
    active: false,
    shortlist: false,
    firstname: "Timmy",
    lastname: "Simons",
    club: "Club Brugge",
    games: "318",
    logoUrl: "images/clubs/Club_Brugge.png",
    imageUrl: "players/images/Timmy_Simons.jpg",
    video: "players/videos/Timmy_Simons.mp4?v=1",
  },
  {
    id: 53,
    active: false,
    shortlist: false,
    firstname: "Vincent",
    lastname: "Kompany",
    club: "RSC Anderlecht",
    games: "88",
    logoUrl: "images/clubs/RSC_Anderlecht.png",
    imageUrl: "players/images/Vincent_Kompany.jpg",
    video: "players/videos/Vincent_Kompany.mp4?v=1",
  },
  {
    id: 54,
    active: false,
    shortlist: false,
    firstname: "Wesley",
    lastname: "Sonck",
    club: "KRC Genk",
    games: "93",
    logoUrl: "images/clubs/KRC_Genk.png",
    imageUrl: "players/images/Wesley_Sonck.jpg",
    video: "players/videos/Wesley_Sonck.mp4?v=1",
  },
  {
    id: 55,
    active: false,
    shortlist: false,
    firstname: "Wilfried",
    lastname: "Van Moer",
    club: "Wildcards",
    games: "198",
    logoUrl: "images/clubs/Wildcards.png?v=1",
    imageUrl: "players/images/Wilfried_Van_Moer.jpg",
    video: "players/videos/Wilfried_Van_Moer.mp4?v=1",
  },
  {
    id: 56,
    active: false,
    shortlist: false,
    firstname: "Wouter",
    lastname: "Corstjens",
    club: "Patro Eisden Maasmechelen",
    games: "29",
    logoUrl: "images/clubs/Patro_Eisden_Maasmechelen.png",
    imageUrl: "players/images/Wouter_Corstjens.jpg",
    video: "players/videos/Wouter_Corstjens.mp4?v=1",
  },
]);
