import Experience from "./Experience.js";
import { get } from "svelte/store";
import { state } from "../store/state.js";
import { LOOK_AROUND_STATES, STATE } from "./config.js";
import Hammer from "hammerjs";

export default class Shirts {
  constructor() {
    this.experience = new Experience();
    this.raycaster = this.experience.raycaster;
    this.controls = this.experience.controls;
    this.cursor = this.experience.cursor;
    this.mobileAndTabletCheck = this.experience.mobileAndTabletCheck;
    this.resources = this.experience.resources;
  }

  initEvents() {
    if (!this.mobileAndTabletCheck.check()) {
      window.addEventListener("pointermove", (event) => {
        this.cursor.hover(event);

        if (!this.resources.loadingComplete) {
          return;
        }

        this.raycaster.pointerMoveHandler(event.clientX, event.clientY);

        if (!LOOK_AROUND_STATES.includes(get(state))) {
          return;
        }

        this.controls.lookAroundControls.animateCamera();
      });

      document.querySelector("canvas").addEventListener("click", (event) => {
        if (!this.resources.loadingComplete) {
          return;
        }

        if (get(state) === STATE.PLAYER_DETAIL) {
          this.raycaster.pointerMoveHandler(event.clientX, event.clientY);
          this.experience.world.frame.prevNextHandler();
        }

        if (get(state) === STATE.FIRST_PERSON_ACTIVE_AND_HOVERING) {
          this.experience.world.frame.clickHandler();
        }

        if (get(state) === STATE.FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO) {
          this.experience.world.wall.clickVideoWallHandler();
        } else if (get(state) === STATE.VIDEO_DETAIL) {
          this.experience.world.wall.exitVideoWallHandler();
        }
      });
    }
  }

  initScrollControls() {
    document.addEventListener("wheel", (e) => {
      if (
        get(state) !== STATE.FIRST_PERSON_ACTIVE &&
        get(state) !== STATE.FIRST_PERSON_ACTIVE_AND_HOVERING &&
        get(state) !== STATE.FIRST_PERSON_ACTIVE_AND_HOVERINGVIDEO
      ) {
        return;
      }

      this.controls.scrollControls.scrollHandler(e);
    });
  }

  initPanControls() {
    const hammer = new Hammer(document.body);
    // hammer.get('tap').set({
    //   threshold: 10
    // });
    // hammer.get('pan').set({
    //   direction: Hammer.DIRECTION_ALL,
    //   threshold: 0
    // });
    hammer.on("panstart", (ev) => {
      if (
        ev.target.className !== "webgl" ||
        get(state) !== STATE.FIRST_PERSON_ACTIVE
      ) {
        return;
      }

      this.controls.panControls.panStartHandler(ev);
    });
    hammer.on("panleft panright", (ev) => {
      if (ev.target.className !== "webgl") {
        return;
      }

      // swipe when in detail state
      if (get(state) === STATE.PLAYER_DETAIL) {
        if (ev.type === "panleft") {
          this.experience.world.frame.prevNextHandler("next");
        } else if (ev.type === "panright") {
          this.experience.world.frame.prevNextHandler("prev");
        }
      }

      // swipe when in first person state
      if (get(state) === STATE.FIRST_PERSON_ACTIVE) {
        this.controls.panControls.panHandler(ev);
      }
    });
    hammer.on("panend", (ev) => {
      if (
        ev.target.className !== "webgl" ||
        get(state) !== STATE.FIRST_PERSON_ACTIVE
      ) {
        return;
      }

      this.controls.panControls.panEndHandler(ev);
    });
    hammer.on("tap", (ev) => {
      if (
        ev.target.className !== "webgl" ||
        (get(state) !== STATE.FIRST_PERSON_ACTIVE &&
          get(state) !== STATE.VIDEO_DETAIL)
      ) {
        return;
      }

      this.raycaster.touchHandler(ev);
      this.experience.world.frame.clickHandler();

      if (get(state) === STATE.FIRST_PERSON_ACTIVE) {
        this.experience.world.wall.clickVideoWallHandler();
      } else if (get(state) === STATE.VIDEO_DETAIL) {
        this.experience.world.wall.exitVideoWallHandler();
      }
    });
  }
}
