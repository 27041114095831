import { get } from "svelte/store";
import { players } from "../store/players.js";

const items = [
  // Font
  {
    id: "font-aeonik",
    type: "font",
    path: "/fonts/Aeonik-Bold.woff2",
    weight: "700",
  },
  {
    id: "font-aeonik",
    type: "font",
    path: "/fonts/Aeonik-Regular.woff2",
    weight: "400",
  },
  {
    id: "backdropWhite",
    type: "texture",
    path: "textures/backdrop-white-4.png",
  },
  {
    id: "backdropGold",
    type: "texture",
    path: "textures/backdrop-gold-3.png",
  },
  {
    id: "frameVideoOverlayNew",
    type: "texture",
    path: "textures/video-mask.png",
  },
  {
    id: "frameVideoOverlay",
    type: "texture",
    path: "textures/frame-mask.png",
  },
  {
    id: "wallVideoOverlay",
    type: "texture",
    path: "textures/simpleShadow4.png",
  },
  {
    id: "baked",
    type: "texture",
    path: "textures/baked-7.jpg",
  },
  {
    id: "frameModel",
    type: "gltfModel",
    path: "models/frame_back_only.glb",
  },
  {
    id: "hallOfFameModel",
    type: "gltfModel",
    path: "models/hall_of_fame_16.glb",
  },
];

const playerList = get(players);

for (let i = 0; i < playerList.length; i++) {
  items.push({
    id: `player-${playerList[i].id}`,
    type: "texture",
    path: playerList[i].imageUrl,
  });
}

export default items;
