import Experience from "./../Experience.js";
import gsap from "gsap";
import { state } from "../../store/state.js";
import {
  INTRO_ANIMATION_END_POSITION_Y,
  LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP,
  LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_MOBILE,
  STATE,
} from "../config.js";
import * as THREE from "three";
import { get } from "svelte/store";

export default class LookAroundControls {
  constructor() {
    this.experience = new Experience();
    this.raycaster = this.experience.raycaster;
    this.camera = this.experience.camera;
    this.sizes = this.experience.sizes;
    this.isMobile = this.experience.mobileAndTabletCheck.check();

    this.cameraFactorDesktop = LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP;
    this.cameraFactorMobile = LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_MOBILE;
    this.motionPosition = null;
    this.startAngle = null;

    this.initialValue = null;
  }

  /**
   * Animate the camera to position
   */
  animateCamera(duration = 0.1) {
    if (this.isMobile) {
      return;
    }

    gsap.to(this.camera.instance.rotation, {
      duration: duration,
      delay: 0,
      x: this.raycaster.cursor.y * this.cameraFactorDesktop,
      y: this.raycaster.cursor.x * -this.cameraFactorDesktop,
      z:
        this.raycaster.cursor.x *
        -this.cameraFactorDesktop *
        -(this.raycaster.cursor.y * this.cameraFactorDesktop),
    });
  }

  animateCameraMobile(duration = 0.1) {
    if (!this.isMobile || !this.motionPosition) {
      return;
    }

    gsap.to(this.camera.instance.rotation, {
      duration: duration,
      delay: 0,
      y: this.motionPosition.z,
      x: this.motionPosition.y,
      z: this.motionPosition.z * this.motionPosition.y,
    });
  }

  deviceOrientationHandler() {
    window.addEventListener("deviceorientation", (e) => {
      if (
        get(state) === STATE.SHORTLIST_ACTIVE ||
        get(state) === STATE.EXPERIENCE_START
      ) {
        return;
      }

      this.cameraPositionForMotion(e);
      this.animateCameraMobile(0.5);
    });
  }

  async requestPermission() {
    return new Promise((resolve) => {
      if (
        typeof DeviceMotionEvent === "undefined" ||
        typeof DeviceOrientationEvent.requestPermission !== "function"
      ) {
        this.deviceOrientationHandler();

        resolve(true);
        return;
      }

      DeviceOrientationEvent.requestPermission().then((r) => {
        if (r === "granted") {
          this.deviceOrientationHandler();

          resolve(true);
        } else if (r === "denied") {
          resolve(false);
        }
      });
    });
  }

  /**
   * Return the position of the camera for motion interactions
   * @param e
   * @returns {{y: number, z: number}}
   */
  cameraPositionForMotion(e) {
    if (!this.initialValue) {
      this.initialValue = e.alpha;
    }

    const angles = {
      alpha: e.alpha - this.initialValue,
      beta: e.beta,
      gamma: e.gamma,
    };

    const rotation = this.getEulerAngles(
      this.getRotationMatrix(angles.alpha, angles.beta, angles.gamma),
    );
    let updown = rotation[1].toFixed(2);
    let leftright = (rotation[2] + 90).toFixed(2);

    if (!this.startAngle) {
      this.startAngle = parseInt(updown);
    }

    // Set min and max for up and down
    if (updown > this.startAngle + 80) {
      updown = this.startAngle + 80;
    } else if (updown < this.startAngle + -10) {
      updown = this.startAngle + -10;
    }

    // Set min and max for left and right
    if (leftright < -20 || leftright > 180) {
      leftright = -20;
    } else if (leftright > 20) {
      leftright = 20;
    }

    // Set Orientation indicator position
    document.documentElement.style.setProperty(
      "--leftright",
      `${leftright / 0.4 + 50}%`,
    );
    document.documentElement.style.setProperty(
      "--updown",
      `${(1.111 * (this.startAngle * -1 + parseFloat(updown)) + 11.11).toFixed(2)}%`,
    );

    // Set motionPosition
    this.motionPosition = {
      y: (updown - this.startAngle) * this.cameraFactorMobile,
      z:
        leftright *
        (this.cameraFactorMobile * (this.sizes.width / this.sizes.height)),
    };
  }

  /**
   * Calculate the rotation matrix
   * @param alpha
   * @param beta
   * @param gamma
   * @returns {number[]}
   */
  getRotationMatrix(alpha, beta, gamma) {
    const degtorad = Math.PI / 180; // Degree-to-Radian conversion
    const cX = Math.cos(beta * degtorad);
    const cY = Math.cos(gamma * degtorad);
    const cZ = Math.cos(alpha * degtorad);
    const sX = Math.sin(beta * degtorad);
    const sY = Math.sin(gamma * degtorad);
    const sZ = Math.sin(alpha * degtorad);

    const m11 = cZ * cY - sZ * sX * sY;
    const m12 = -cX * sZ;
    const m13 = cY * sZ * sX + cZ * sY;

    const m21 = cY * sZ + cZ * sX * sY;
    const m22 = cZ * cX;
    const m23 = sZ * sY - cZ * cY * sX;

    const m31 = -cX * sY;
    const m32 = sX;
    const m33 = cX * cY;

    return [m13, m11, m12, m23, m21, m22, m33, m31, m32];
  }

  /**
   * calculate the euler angles
   * @param matrix
   * @returns {number[]}
   */
  getEulerAngles(matrix) {
    const radtodeg = 180 / Math.PI; // Radian-to-Degree conversion
    const sy = Math.sqrt(matrix[0] * matrix[0] + matrix[3] * matrix[3]);
    const singular = sy < 1e-6; // If
    let x;
    let y;
    let z;

    if (!singular) {
      x = Math.atan2(matrix[7], matrix[8]);
      y = Math.atan2(-matrix[6], sy);
      z = Math.atan2(matrix[3], matrix[0]);
    } else {
      x = Math.atan2(-matrix[5], matrix[4]);
      y = Math.atan2(-matrix[6], sy);
      z = 0;
    }

    return [radtodeg * x, radtodeg * y, radtodeg * z];
  }
}
