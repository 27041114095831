<script>
  import {state} from '../../store/state.js';
  import {selectedLanguage} from '../../store/selectedLanguage.js';
  import {STATE} from '../../Experience/config.js';
  import {fade} from 'svelte/transition';
  import {setLanguage} from '../services/languageService.js';

  export let type = 'default';

  let activeId;
  const languages = [
    {
      id: 'nl',
      label: 'Nederlands',
      shortLabel: 'NL'
    }, {
      id: 'fr',
      label: 'Français',
      shortLabel: 'FR'
    }, {
      id: 'en',
      label: 'English',
      shortLabel: 'EN'
    }
  ];

  const chooseLanguage = (language) => {
    setLanguage(language);

    // window.Video.setWallVideo();

    if ($state === STATE.LANG_SELECTOR) {
      state.set(STATE.LANG_SELECTED);
    }
  }

  selectedLanguage.subscribe(value => {
    activeId = value;
  });
</script>

{#if type === 'default'}
	<div class="lang-selector">
		<div class="items">
			{#each languages as language}
				<button class="btn btn--large btn--border" on:click={() => chooseLanguage(language.id)}>
					<span class="btn__label">
						<span>{language.label}</span>
						<span>{language.label}</span>
					</span>
				</button>
			{/each}
		</div>
	</div>
{:else if type === 'small'}
	<div class="lang-selector-small btn" transition:fade={{ duration: 300 }}>
		<select class="lang-selector-small__select" bind:value={activeId} on:change={() => chooseLanguage(activeId)}>
			{#each languages as language}
				<option value="{language.id}">{language.shortLabel}</option>
			{/each}
		</select>
	</div>
{/if}

<style>
  .items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .btn--border {
    width: 260px;
    height: 72px;
    padding: 32px;
    text-align: center;
    align-content: center;
  }

  .lang-selector-small {
    position: fixed;
    top: 24px;
    right: 24px;
    height: 40px;
    padding: 0;
    background-color: transparent;
    appearance: none;
    background-image: url(/images/icons/icon-chevron-down.svg);
    background-position: right 8px top 50%;
    background-size: 16px;
    background-repeat: no-repeat;
    border-radius: 0;
    color: var(--color-white);
    transition: 0.2s ease all;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
    box-sizing: border-box;
  }

  .lang-selector-small__select {
    appearance: none;
    background-color: transparent;
    border: none;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    pointer-events: all;
    padding: 4px 32px 4px 16px;
  }

  .lang-selector-small__select option {
	background-color: var(--color-grey-darker);
	color: white;
  }

  @media screen and (min-width: 640px) {
    .lang-selector-small {
      top: 48px;
      right: 64px;
      height: 48px;
    }

    .lang-selector-small__select {
      padding: 4px 32px 4px 16px;
    }

    .btn--border {
      width: 240px;
      height: 96px;
    }
  }

  @media screen and (min-width: 880px) {
    .items {
      flex-direction: row;
      gap: 24px;
    }
  }
</style>
