import * as THREE from "three";
import Experience from "./Experience.js";
import {
  INTRO_ANIMATION_DURATION,
  INTRO_ANIMATION_END_POSITION_X,
  INTRO_ANIMATION_END_POSITION_Y,
  INTRO_ANIMATION_END_POSITION_Z,
  LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP,
  SKIP_INTRO_CAMERA_ANIMATION,
  STATE,
} from "./config.js";
import { state } from "../store/state.js";
import { get } from "svelte/store";
import {
  getStorateItem,
  STORAGE_KEYS,
} from "../svelte/services/storageService.js";
import { shortlistedPlayers } from "../store/shortlistedPlayers.js";
import { setLanguage } from "../svelte/services/languageService.js";
import { initControls } from "../svelte/services/controlsService.js";
import { players } from "../store/players.js";

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.time = this.experience.time;
    this.controls = this.experience.controls;
    this.camera = this.experience.camera.instance;
    this.cameraContainer = this.experience.camera.cameraContainer;
    this.raycaster = this.experience.raycaster;
    this.mobileAndTabletCheck = this.experience.mobileAndTabletCheck;

    this.startTime = null;
    this.startTimeZoom = null;
    this.cameraAnimationSpeed =
      INTRO_ANIMATION_DURATION /
      (INTRO_ANIMATION_DURATION * INTRO_ANIMATION_DURATION);
  }

  setPathPhase1() {
    this.linePhase1Start = new THREE.Vector3(0, 7500, 0);
    this.linePhase1End = new THREE.Vector3(0, 600, 0);

    this.linePhase1Geometry = new THREE.BufferGeometry().setFromPoints([
      this.linePhase1Start,
      this.linePhase1End,
    ]);
    this.linePhase1Material = new THREE.LineBasicMaterial({
      transparent: true,
      opacity: 0,
    });

    this.linePhase1Object = new THREE.Line(
      this.linePhase1Geometry,
      this.linePhase1Material,
    );
    this.scene.add(this.linePhase1Object);
  }

  setPathPhase2() {
    // Line
    this.linePhase2Start = new THREE.Vector3(0, 600, 0);
    this.linePhase2End = new THREE.Vector3(0, 300, 0);

    this.linePhase2Geometry = new THREE.BufferGeometry().setFromPoints([
      this.linePhase2Start,
      this.linePhase2End,
    ]);
    this.linePhase2Material = new THREE.LineBasicMaterial({
      transparent: true,
      opacity: 0,
    });

    this.linePhase2Object = new THREE.Line(
      this.linePhase2Geometry,
      this.linePhase2Material,
    );
    this.scene.add(this.linePhase2Object);

    // Curve
    this.curvePhase2 = new THREE.CubicBezierCurve3(
      new THREE.Vector3(0, 300, 0),
      new THREE.Vector3(0, 200, 0),
      new THREE.Vector3(-100, 9, -100),
      new THREE.Vector3(0, INTRO_ANIMATION_END_POSITION_Y, 0),
    );

    this.curvePhase2Points = this.curvePhase2.getPoints(50);
    this.curvePhase2Geometry = new THREE.BufferGeometry().setFromPoints(
      this.curvePhase2Points,
    );
    this.curvePhase2Material = new THREE.LineBasicMaterial({
      transparent: true,
      opacity: 0,
    });

    this.curvePhase2Object = new THREE.Line(
      this.curvePhase2Geometry,
      this.curvePhase2Material,
    );
    this.scene.add(this.curvePhase2Object);
  }

  animatePhase1() {
    if (!this.startTimeZoom) {
      this.startTimeZoom = this.time.elapsed;
      this.setPathPhase1();
    }

    this.elapsedTime = (this.time.elapsed - this.startTimeZoom) / 1000;

    if (this.elapsedTime * this.cameraAnimationSpeed >= 1) {
      state.set(STATE.LANG_SELECTOR);
      return;
    }

    const factor = (this.elapsedTime * this.cameraAnimationSpeed) % 1.0;
    const newFactor = this.easeInOut(factor);
    const pathTarget = new THREE.Vector3().lerpVectors(
      this.linePhase1Start,
      this.linePhase1End,
      newFactor,
    );

    this.camera.lookAt(20, INTRO_ANIMATION_END_POSITION_Y, 0);
    this.camera.position.copy(pathTarget);
  }

  animatePhase2() {
    if (!this.startTime) {
      this.startTime = this.time.elapsed;
      this.setPathPhase2();
    }

    this.elapsedTime = (this.time.elapsed - this.startTime) / 1000;

    if (this.elapsedTime * this.cameraAnimationSpeed >= 1) {
      this.cameraAnimationComplete();
      return;
    }

    if (
      this.mobileAndTabletCheck.check() &&
      !this.controls.lookAroundControls.motionPosition
    ) {
      return;
    }

    let pathTarget = new THREE.Vector3(0, 0, 0);
    const progress = (this.elapsedTime * this.cameraAnimationSpeed) % 1.0;
    const easedProgress = this.easeInOut(progress);
    const convertedEasedProgress = (easedProgress - 0.5) * 2;

    const targetPositionX = this.mobileAndTabletCheck.check()
      ? -this.controls.lookAroundControls.motionPosition.y
      : this.raycaster.cursor.y * -LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP;
    const targetPositionY = this.mobileAndTabletCheck.check()
      ? this.controls.lookAroundControls.motionPosition.z
      : this.raycaster.cursor.x * -LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP;
    const targetPositionZ =
      this.raycaster.cursor.x *
      -LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP *
      -(this.raycaster.cursor.y * LOOK_AROUND_CONTROLS_FACTOR_DEFAULT_DESKTOP);

    if (easedProgress < 0.5) {
      pathTarget = new THREE.Vector3().lerpVectors(
        this.linePhase2Start,
        this.linePhase2End,
        easedProgress * 2,
      );
      this.camera.lookAt(20, INTRO_ANIMATION_END_POSITION_Y, 0);
    } else {
      this.curvePhase2.getPoint(convertedEasedProgress, pathTarget);
      // Account for the cursor/pan position during the second half of the animation
      const targetPosition = new THREE.Vector3(
        20,
        INTRO_ANIMATION_END_POSITION_Y,
        convertedEasedProgress * 20,
      );
      let directionVector = new THREE.Vector3().subVectors(
        targetPosition,
        this.camera.position,
      );
      let axisX = new THREE.Vector3(1, 0, 0);
      let axisY = new THREE.Vector3(0, 1, 0);
      let axisZ = new THREE.Vector3(0, 0, 1);
      directionVector.applyAxisAngle(
        axisX,
        convertedEasedProgress * targetPositionX,
      );
      directionVector.applyAxisAngle(
        axisY,
        convertedEasedProgress * targetPositionY,
      );
      directionVector.applyAxisAngle(
        axisZ,
        convertedEasedProgress * targetPositionZ,
      );

      let newTargetPosition = new THREE.Vector3().addVectors(
        this.camera.position,
        directionVector,
      );
      this.camera.lookAt(newTargetPosition);
    }

    this.camera.position.copy(pathTarget);
  }

  cameraAnimationComplete() {
    // Set the exact end position after the end animation
    this.camera.position.set(
      INTRO_ANIMATION_END_POSITION_X,
      INTRO_ANIMATION_END_POSITION_Y,
      INTRO_ANIMATION_END_POSITION_Z,
    );
    this.cameraContainer.rotation.set(0, Math.PI + Math.PI / 4, 0);

    // Reset the camera rotation to 0,0,0, and then update the controls
    this.controls.lookAroundControls.animateCamera(0);
    this.controls.lookAroundControls.animateCameraMobile(0);
    this.controls.scrollControls.updateRotationScroll();
    this.controls.panControls.updateRotationAfterAnimation();

    // Set the state
    state.set(STATE.FIRST_PERSON_ACTIVE);
  }

  easeInOut(x) {
    return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
  }

  update() {
    if (
      get(state) === STATE.INTRO_ANIMATION_START &&
      !SKIP_INTRO_CAMERA_ANIMATION
    ) {
      this.animatePhase2();
    }

    if (get(state) === STATE.INTRO_ZOOM) {
      const hallOfFameData = getStorateItem(STORAGE_KEYS.HALL_OF_FAME);

      if (typeof hallOfFameData?.language !== "undefined") {
        initControls();
        setLanguage(hallOfFameData.language);
        shortlistedPlayers.set(hallOfFameData.players);

        hallOfFameData.players.forEach((player) => {
          const tempPlayers = get(players);
          tempPlayers
            .filter((p) => p.id === player)
            .forEach((player) => {
              player.shortlist = true;
            });

          players.set(tempPlayers);
          window.Frame.addToShortlist(player, true);
        });

        this.cameraAnimationComplete();

        state.set(STATE.SHORTLIST_SAVE);

        return;
      }

      this.animatePhase1();
    }
  }
}
