<script>
  export let type = 'default';

  const partners = [
    {
      id: 'sporza',
      name: 'Sporza',
      url: 'https://sporza.be/'
    },
    {
      id: 'alzheimer-liga',
      name: 'Alzheimer Liga',
      url: 'https://www.alzheimerliga.be/'
    },
    {
      id: 'pro-league',
      name: 'Pro League',
      url: 'https://www.proleague.be/'
    }
  ]
</script>

<div class="partners partners--{type}">
	{#each partners as partner}
		<a class="item" href="{partner.url}" target="_blank">
			<img class="image" src="/images/partners/logo-{partner.id}.png" alt="{partner.name}">
		</a>
	{/each}
</div>

<style>
  .partners {
    position: absolute;
    left: 0;
    bottom: 16px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px 32px;
    padding: 16px;
    box-sizing: border-box;
  }

  @media screen and (min-width: 640px) {
    .partners {
      bottom: 48px;
    }
  }

  .image {
    max-height: 24px;
    max-width: 80px;
  }

  @media screen and (min-width: 640px) {
    .image {
      max-height: 40px;
      max-width: 100px;
    }
  }
</style>
