<script>
  import {fade} from 'svelte/transition';
  import Player from './Player.svelte';
  import {state} from '../../store/state.js';
  import {STATE} from '../../Experience/config.js';
  import {translate} from '../../translations/index.js';
  import {getSsoUrl} from '../services/unidyService.js';
  import {getPlayersByShortlist} from '../services/shortlistService.js';
  import {getStorateItem, STORAGE_KEYS} from '../services/storageService.js';
  import {completeSubmission} from '../services/completeService.js';

  const confirm = async () => {
    if (getStorateItem(STORAGE_KEYS.HALL_OF_FAME) && getStorateItem(STORAGE_KEYS.UNIDY_TOKEN)) {
      await completeSubmission();
      return;
    }

    window.location.href = getSsoUrl();
  }

  const close = () => {
    state.set(STATE.SHORTLIST_ACTIVE);
  }
</script>

<div class="overlay" transition:fade={{ duration: 300 }}>
	<div class="overlay__content">
		<h3 class="overlay__title">{translate('hof.shortlist-confirm.title')}</h3>

		<p class="overlay__text">
			{translate('hof.shortlist-confirm.text')}
		</p>

		<div class="overlay__players">
			{#each getPlayersByShortlist() as player}
				<Player bind:player confirm={true} />
			{/each}
		</div>

		<div class="overlay__buttons">
			<button class="btn btn--primary" on:click={() => confirm()}>
				{translate('hof.shortlist-confirm.btn.confirm')}
			</button>

			<button class="btn btn--tertiary-inverted" on:click={() => close()}>
				{translate('hof.shortlist-confirm.btn.back')}
			</button>
		</div>
	</div>
</div>

<style>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: var(--color-black-60);
    display: flex;
    pointer-events: all;
	overflow: auto;
  }

  .overlay__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 640px;
    margin: auto;
    background-color: white;
    padding: 64px 16px;
    box-sizing: border-box;
  }

  .overlay__title {
    margin-bottom: 16px;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1;
  }

  .overlay__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 12px;
  }

  .overlay__players {
	margin-bottom: 24px;
  }

  :global(.overlay__players .player:not(:last-child)) {
	border-color: var(--color-black-20);
  }

  .overlay__buttons {
	display: flex;
	gap: 12px;
  }

  @media screen and (min-width: 640px) {
    .overlay__content {
	  padding: 64px;
	}
  }
</style>
