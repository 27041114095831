uniform float opacity;
uniform sampler2D tDiffuse;
uniform vec3 overlayColor;
varying vec2 vUv;

void main() {
    vec4 sceneColor = texture2D(tDiffuse, vUv);
    vec4 overlayColor = vec4(overlayColor.rgb, 1.0);
    gl_FragColor = mix(sceneColor, overlayColor, opacity);
}
