export default class VideoService {
  setVideo(id, path) {
    const existingElement = document.getElementById(id);

    if (existingElement) {
      existingElement.remove();
    }

    const videoElement = document.createElement("video");
    videoElement.id = id;
    videoElement.className = "absolute-video";
    videoElement.setAttribute("playsinline", "playsinline");
    videoElement.setAttribute("muted", "muted");

    const source = document.createElement("source");
    source.src = path;
    source.type = "video/mp4";

    videoElement.appendChild(source);
    document.body.appendChild(videoElement);

    videoElement.autoplay = true;
    videoElement.loop = true;
    videoElement.playsinline = true;
    videoElement.muted = true;

    videoElement.load();
  }

  removeVideo(id) {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    element.remove();
  }
}
