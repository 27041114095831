import translator from "bazinga-translator";
import { languages } from "./languages";

translator.fallback = "nl";
translator.defaultDomain = "general";

Object.keys(languages).forEach((language) => {
  const translations = languages[language];

  Object.keys(translations).forEach((translationDomain) => {
    Object.keys(translations[translationDomain]).forEach((translationKey) => {
      translator.add(
        translationKey,
        translations[translationDomain][translationKey],
        "general",
        language,
      );
    });
  });
});

export const translate = (key, parameters, domain, locale) =>
  translator.trans(key, parameters, domain, locale);
